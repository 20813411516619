import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, AutoComplete, DatePicker, Space  } from "antd";
import dayjs from 'dayjs';

const formatDateStr=(str)=>{
  if (str==='Invalid Date'){
    return ''
  }
  const [month, day, year] = str.split(',')[0].trim().split('/');
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  return formattedDate;
}

const convertLocalToUTC=(localString)=>{
  if (localString==='Invalid Date'){
    return ''
  }
  const date = new Date(localString);
  const utcString = date.toISOString(); // Converts to UTC string format
  return utcString;
}

const convertUTCToLocal = (utcString) => {
  if (utcString==='Invalid Date' || utcString === null || utcString === undefined){
    return ''
  }
  // convert the string to a Date object
  const utcDate = getDateObjFromString(utcString);
  // convert to the Local date string
  return utcDate.toLocaleString();
}

/**
 * Convert the currentAircraftSelected dateTime string to a Date object
 * @param {*} dateTimeStr The dateTimeStr string get from the currentAircraftSelected, e.g.  "5/7/2024, 1:32:24 PM"
 * @returns A javascript Date object of the given dateTimeStr string
 */
const getDateObjFromString = (dateTimeStr) => {
  const [datePart, timePart] = dateTimeStr.split(", ");   // Split the string into date and time parts
  const [month, day, year] = datePart.split("/");         // Extract month, day, and year
  const [time, meridian] = timePart.split(" ");           // Extract time and AM/PM

  // Adjust hours for PM time
  const [hours, minutes, seconds] = time.split(":");
  let hourOffset = 0;
  if (meridian === "PM") {
      hourOffset = 12;
  }

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours) + hourOffset, parseInt(minutes), parseInt(seconds));
}

const getTime=(str)=>{
  if (str==='Invalid Date'){
    return ''
  }
  const timePart = str.split(',')[1].trim();
  const [time, period] = timePart.split(' ');

  let [hours, minutes, seconds] = time.split(':');

  // Convert to 24-hour format
  if (period.toLowerCase() === 'pm' && hours !== '12') {
    hours = String(parseInt(hours, 10) + 12);
  } else if (period.toLowerCase() === 'am' && hours === '12') {
    hours = '00';
  }
  // Use padStart to ensure hours, minutes, and seconds have two digits
  const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  
  return formattedTime;
}

const getISOStringFromDayjsObj = (d) => {
  let date = new Date(d);
  // remove the value of second and millisecond
  date.setSeconds(0, 0);
  return date.toISOString();
}

const timeTypes = {
  TIME_TYPE_ENG_START: "engine_start",
  TIME_TYPE_ENG_STOP: "engine_stop",
  TIME_TYPE_TAKEOFF: "takeoff",
  TIME_TYPE_LANDING: "landing",
}

const Boundary = {
  LEFT: "L",
  RIGHT: "R",
  NOT_BOUND: "NOT"
}

// the format for setting the currentAircraftSelected, e.g."5/7/2024, 1:32:24 PM"
const formatString = 'M/D/YYYY, h:mm:ss A';

export function EditFlightModal(props) {
  const {
    editModalVisible,
    setEditModalVisible,
    currentAircraftSelected,
    setCurrentFlightSelected,
    handleUpdate,
    handleDelete,
    setAlertMessage,
    setShowAlert,
    setAlertType,
  } = props;

  const [form] = Form.useForm();

  const getNewRowDataFromCurrentAircraftSelected = () => ({
    key: currentAircraftSelected.key,
    registration: currentAircraftSelected.registration,
    engine_start: convertLocalToUTC(currentAircraftSelected.engine_start),
    engine_stop: convertLocalToUTC(currentAircraftSelected.engine_stop),
    takeoff: convertLocalToUTC(currentAircraftSelected.takeoff),
    landing: convertLocalToUTC(currentAircraftSelected.landing),
    pilot_name: currentAircraftSelected.pilot_name,
    flight_id: currentAircraftSelected.flight_id
  });

  // init the newRowData hook using the currentAircraftSelected got from the parent component
  // notice: we keep UCT time in newRowData and the database
  const [newRowData, setNewRowData] = useState(getNewRowDataFromCurrentAircraftSelected);

  const [timeBoundaries, setTimeBoundaries] = useState({
    engine_start: {
      left: null,
      right: null,
    },
    engine_stop: {
      left: null,
      right: null,
    },
    takeoff: {
      left: null,
      right: null,
    },
    landing: {
      left: null,
      right: null,
    }
  });

  // update the boundaries for each time as long as the newRowData changes
  useEffect(() => {
    setTimeBoundaries({
      engine_start: {
        left: null,
        right: newRowData.takeoff ? newRowData.takeoff : newRowData.landing ? newRowData.landing : newRowData.engine_stop ? newRowData.engine_stop : null,
      },
      engine_stop: {
        left: newRowData.landing ? newRowData.landing : newRowData.takeoff ? newRowData.takeoff : newRowData.engine_start ? newRowData.engine_start : null,
        right: null,
      },
      takeoff: {
        left: newRowData.engine_start ? newRowData.engine_start : null,
        right: newRowData.landing ? newRowData.landing : newRowData.engine_stop ? newRowData.engine_stop : null,
      },
      landing: {
        left: newRowData.takeoff ? newRowData.takeoff : newRowData.engine_start ? newRowData.engine_start : null,
        right: newRowData.engine_stop ? newRowData.engine_stop : null,
      }
    });
  }, [newRowData]);

  useEffect(() => {
    // filling the form using the data fetched
    // notice: we keep to show the user with the local time
    form.setFieldsValue({
      registration: currentAircraftSelected.registration,
      engine_start: currentAircraftSelected.engine_start === "Invalid Date" ? "" : dayjs(convertUTCToLocal(currentAircraftSelected.engine_start)),
      engine_stop: currentAircraftSelected.engine_stop === "Invalid Date" ? "" : dayjs(convertUTCToLocal(currentAircraftSelected.engine_stop)),
      takeoff: currentAircraftSelected.takeoff === "Invalid Date" ? "" : dayjs(convertUTCToLocal(currentAircraftSelected.takeoff)),
      landing: currentAircraftSelected.landing === "Invalid Date" ? "" : dayjs(convertUTCToLocal(currentAircraftSelected.landing)),
      pilot_name: currentAircraftSelected.pilot_name
    });
  }, [currentAircraftSelected]);


  const handleCancel = () => {
    handleDelete(newRowData);
    setEditModalVisible(false);
  };

  // after the flight edit form submitted successfully
  const handleOk = () => {
    // update the database
    handleUpdate(newRowData);
    setEditModalVisible(false);
  };

  const handleClose = () => {
    setEditModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setAlertMessage("Failed to update aircraft.");
    setShowAlert(true);
    setAlertType("error");
  };

  // every time there is a value changed in the form
  const onChange = (values) => {
    setNewRowData({
      ...newRowData,
      ...values,
    });
  };

  // define what to do after clicking ok on the DatePicker
  // only when clicking on the ok btn, this will be called
  const onDatePickerOk = (value) => {

  };

  // only when the valued changed, this will be called
  // even though the ok btn is clicked, if no value changing, this will NOT be called
  const onChangeDatePicker = (value, timeType) => {
    if (!timeType in timeTypes) {
      return
    }

    // remove the value of second and millisecond
    // and convert the user picked value into UTC string with ISO format
    const utcString = getISOStringFromDayjsObj(value);

    // for updating the currentAircraftSelected
    const formattedDateTime = dayjs(value).format(formatString);

    // update the corresponding newRowData with the converted UTC string
    if (timeType === timeTypes.TIME_TYPE_ENG_START) {
      setNewRowData({...newRowData, engine_start: utcString});
      setCurrentFlightSelected({...currentAircraftSelected, engine_start: formattedDateTime});

    } else if (timeType === timeTypes.TIME_TYPE_ENG_STOP) {
      setNewRowData({...newRowData, engine_stop: utcString});
      setCurrentFlightSelected({...currentAircraftSelected, engine_stop: formattedDateTime});

    } else if (timeType === timeTypes.TIME_TYPE_TAKEOFF) {
      setNewRowData({...newRowData, takeoff: utcString});
      setCurrentFlightSelected({...currentAircraftSelected, takeoff: formattedDateTime});

    } else if (timeType === timeTypes.TIME_TYPE_LANDING) {
      setNewRowData({...newRowData, landing: utcString});
      setCurrentFlightSelected({...currentAircraftSelected, landing: formattedDateTime});

    }
  };

  // ------------------------------------ disable date ------------------------------------

  /**
   * engine_start -> takeoff -> landing -> engine_stop
   * engine_start should be before all the rest
   * @param {dayjs.Dayjs} d 
   * @returns true for disable selection, false for not
   */
  const disabledEngineStartDate = (d) => {
    if (!d) return true;
    if (timeBoundaries.engine_start.right) {
      return d.isAfter(dayjs(timeBoundaries.engine_start.right).add(1, 'day').startOf('day'));
    }
    return false;
  }

  /**
   * engine_start -> takeoff -> landing -> engine_stop
   * engine_stop should be after all the rest
   * @param {dayjs.Dayjs} d 
   * @returns true for disable selection, false for not
   */
  const disabledEngineStopDate = (d) => {
    if (!d) return true;
    if (timeBoundaries.engine_stop.left) {
      return d.isBefore(dayjs(timeBoundaries.engine_stop.left).subtract(1, 'day').endOf('day'));
    }
    return false;
  }

  /**
   * engine_start -> takeoff -> landing -> engine_stop
   * takeoff time should be after the engine_start and before the landing and engine_stop
   * @param {dayjs.Dayjs} d 
   * @returns true for disable selection, false for not
   */
  const disabledTakeOffDate = (d) => {
    if (!d) return true;
    if (timeBoundaries.takeoff.left) {
      if (timeBoundaries.takeoff.right) {
        return (d.isBefore(dayjs(timeBoundaries.takeoff.left).subtract(1, 'day').endOf('day')) 
              || d.isAfter(dayjs(timeBoundaries.takeoff.right).add(1, 'day').startOf('day'))) ;
      }
      return d.isBefore(dayjs(timeBoundaries.takeoff.left).subtract(1, 'day').endOf('day'));
    } else {
      if (timeBoundaries.takeoff.right) {
        return d.isAfter(dayjs(timeBoundaries.takeoff.right).add(1, 'day').startOf('day'));
      }
    }
    return false;
  }

  /**
   * engine_start -> takeoff -> landing -> engine_stop
   * lading time should after the takeoff and engine_start, before the engine_stop
   * @param {dayjs.Dayjs} d 
   * @returns true for disable selection, false for not
   */
  const disabledLandingDate = (d) => {
    if (!d) return true;
    if (timeBoundaries.landing.left) {
      if (timeBoundaries.landing.right) {
        return (d.isBefore(dayjs(timeBoundaries.landing.left).subtract(1, 'day').endOf('day')) 
              || d.isAfter(dayjs(timeBoundaries.landing.right).add(1, 'day').startOf('day'))) ;
      }
      return d.isBefore(dayjs(timeBoundaries.landing.left).subtract(1, 'day').endOf('day'));
    } else {
      if (timeBoundaries.landing.right) {
        return d.isAfter(dayjs(timeBoundaries.landing.right).add(1, 'day').startOf('day'));
      }
    }
    return false;
  }

  // ------------------------------------ disable time ------------------------------------

  const disabledEngineStartTime = (d) => {
    // do not disable time if no date selected
    if (!d) return;

    // find the right boundary of engine start date time
    let rightLimit = timeBoundaries.engine_start.right;
    // do not disable time if no date limit
    if (!rightLimit) return;

    // disable time for the right boundary date
    const rightLimitDate = dayjs(rightLimit);
    if (d.startOf('day').isSame(rightLimitDate.startOf('day'))) {
      const disabledHours = Array.from({length: 23 - rightLimitDate.hour()}, (_, i) => i + rightLimitDate.hour() + 1); // [hour + 1, ..., 23]
      if (d.hour() === rightLimitDate.hour()) {
        const disabledMinutes = Array.from({length: 59 - rightLimitDate.minute() + 1}, (_, i) => i + rightLimitDate.minute()); // [minute, minute + 1, ..., 59]
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutes,
        }
      }
      return {
        disabledHours: () => disabledHours,
      }
    }
  };

  const disabledEngineStopTime = (d) => {
    // do not disable time if no date selected
    if (!d) return;
    
    // find the left boundary of engine stop date time
    let leftLimit = timeBoundaries.engine_stop.left;
    // do not disable time if no date limit
    if (!leftLimit) return;

    // disable time for the left boundary date
    // dayjs will convert the UTC to local, therefore, 
    // both d and leftLimitDate are local, so we can compare them
    const leftLimitDate = dayjs(leftLimit);
    if (d.startOf('day').isSame(leftLimitDate.startOf('day'))) {
      const disabledHours = Array.from({length: leftLimitDate.hour()}, (_, i) => i); // [0, 1, 2, ..., hour - 1]
      if (d.hour() === leftLimitDate.hour()) {
        const disabledMinutes = Array.from({length: leftLimitDate.minute() + 1}, (_, i) => i); // [0, 1, 2, ..., minute]
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutes,
        }
      }
      return {
        disabledHours: () => disabledHours,
      }
    }
  };

  const disabledTakeOffTime = (d) => {
    // do not disable time if no date selected
    if (!d) return;

    // if the left and right bound is the same day
    if (timeBoundaries.takeoff.left 
      && timeBoundaries.takeoff.right 
      && dayjs(timeBoundaries.takeoff.left).startOf('day').isSame(dayjs(timeBoundaries.takeoff.right).startOf('day'))
      && d.startOf('day').isSame(dayjs(timeBoundaries.takeoff.left).startOf('day'))
    ) {
      const leftLimitDate = dayjs(timeBoundaries.takeoff.left);
      const rightLimitDate = dayjs(timeBoundaries.takeoff.right);

      const disabledHoursLeft = Array.from({length: leftLimitDate.hour()}, (_, i) => i); // [0, 1, 2, ..., hour - 1]
      const disabledHoursRight = Array.from({length: 23 - rightLimitDate.hour()}, (_, i) => i + rightLimitDate.hour() + 1); // [hour + 1, ..., 23]
      const disabledHours = [...disabledHoursLeft, ...disabledHoursRight];

      const disabledMinutesLeft = Array.from({length: leftLimitDate.minute() + 1}, (_, i) => i); // [0, 1, 2, ..., minute]
      const disabledMinutesRight = Array.from({length: 59 - rightLimitDate.minute() + 1}, (_, i) => i + rightLimitDate.minute()); // [minute, minute + 1, ..., 59]
      const disabledMinutes = [...disabledMinutesLeft, ...disabledMinutesRight];
      
      // if the left and right bound is in the same hour
      if (leftLimitDate.hour() === rightLimitDate.hour()) {
        if (d.hour() === leftLimitDate.hour()) {
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
      
      // left right not same hour, d is on left
      if (d.hour() === leftLimitDate.hour()) {
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutesLeft,
        }
      }

      // left right not same hour, d is on right
      if (d.hour() === rightLimitDate.hour()) {
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutesRight,
        }
      }

      // d is neither on left nor right
      return {
        disabledHours: () => disabledHours,
      }

    }

    // if on the left bound, disable the time according to the left bound
    if (timeBoundaries.takeoff.left) {
      const leftLimitDate = dayjs(timeBoundaries.takeoff.left);
      if (d.startOf('day').isSame(leftLimitDate.startOf('day'))) {
        const disabledHours = Array.from({length: leftLimitDate.hour()}, (_, i) => i); // [0, 1, 2, ..., hour - 1]
        if (d.hour() === leftLimitDate.hour()) {
          const disabledMinutes = Array.from({length: leftLimitDate.minute() + 1}, (_, i) => i); // [0, 1, 2, ..., minute]
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
    }
    // if on the right bound, disable the time according to the right bound
    if (timeBoundaries.takeoff.right) {
      const rightLimitDate = dayjs(timeBoundaries.takeoff.right);
      if (d.startOf('day').isSame(rightLimitDate.startOf('day'))) {
        const disabledHours = Array.from({length: 23 - rightLimitDate.hour()}, (_, i) => i + rightLimitDate.hour() + 1); // [hour + 1, ..., 23]
        if (d.hour() === rightLimitDate.hour()) {
          const disabledMinutes = Array.from({length: 59 - rightLimitDate.minute() + 1}, (_, i) => i + rightLimitDate.minute()); // [minute, minute + 1, ..., 59]
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
    }
  };

  const disabledLandingTime = (d) => {
    // do not disable time if no date selected
    if (!d) return;

    // if the left and right bound is the same day
    if (timeBoundaries.landing.left 
      && timeBoundaries.landing.right 
      && dayjs(timeBoundaries.landing.left).startOf('day').isSame(dayjs(timeBoundaries.landing.right).startOf('day'))
      && d.startOf('day').isSame(dayjs(timeBoundaries.landing.left).startOf('day'))
    ) {
      const leftLimitDate = dayjs(timeBoundaries.landing.left);
      const rightLimitDate = dayjs(timeBoundaries.landing.right);

      const disabledHoursLeft = Array.from({length: leftLimitDate.hour()}, (_, i) => i); // [0, 1, 2, ..., hour - 1]
      const disabledHoursRight = Array.from({length: 23 - rightLimitDate.hour()}, (_, i) => i + rightLimitDate.hour() + 1); // [hour + 1, ..., 23]
      const disabledHours = [...disabledHoursLeft, ...disabledHoursRight];

      const disabledMinutesLeft = Array.from({length: leftLimitDate.minute() + 1}, (_, i) => i); // [0, 1, 2, ..., minute]
      const disabledMinutesRight = Array.from({length: 59 - rightLimitDate.minute() + 1}, (_, i) => i + rightLimitDate.minute()); // [minute, minute + 1, ..., 59]
      const disabledMinutes = [...disabledMinutesLeft, ...disabledMinutesRight];
      
      // if the left and right bound is in the same hour
      if (leftLimitDate.hour() === rightLimitDate.hour()) {
        if (d.hour() === leftLimitDate.hour()) {
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
      
      // left right not same hour, d is on left
      if (d.hour() === leftLimitDate.hour()) {
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutesLeft,
        }
      }

      // left right not same hour, d is on right
      if (d.hour() === rightLimitDate.hour()) {
        return {
          disabledHours: () => disabledHours,
          disabledMinutes: () => disabledMinutesRight,
        }
      }

      // d is neither on left nor right
      return {
        disabledHours: () => disabledHours,
      }

    }

    // if on the left bound, disable the time according to the left bound
    if (timeBoundaries.landing.left) {
      const leftLimitDate = dayjs(timeBoundaries.landing.left);
      if (d.startOf('day').isSame(leftLimitDate.startOf('day'))) {
        const disabledHours = Array.from({length: leftLimitDate.hour()}, (_, i) => i); // [0, 1, 2, ..., hour - 1]
        if (d.hour() === leftLimitDate.hour()) {
          const disabledMinutes = Array.from({length: leftLimitDate.minute() + 1}, (_, i) => i); // [0, 1, 2, ..., minute]
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
    }
    // if on the right bound, disable the time according to the right bound
    if (timeBoundaries.landing.right) {
      const rightLimitDate = dayjs(timeBoundaries.landing.right);
      if (d.startOf('day').isSame(rightLimitDate.startOf('day'))) {
        const disabledHours = Array.from({length: 23 - rightLimitDate.hour()}, (_, i) => i + rightLimitDate.hour() + 1); // [hour + 1, ..., 23]
        if (d.hour() === rightLimitDate.hour()) {
          const disabledMinutes = Array.from({length: 59 - rightLimitDate.minute() + 1}, (_, i) => i + rightLimitDate.minute()); // [minute, minute + 1, ..., 59]
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          }
        }
        return {
          disabledHours: () => disabledHours,
        }
      }
    }

  };

  // ------------------------------------ on select ------------------------------------

  const onSelectEngineStart = (d) => {
    // if d is out of right boundary, reset it to an valid time
    if (timeBoundaries.engine_start.right && d.isAfter(dayjs(timeBoundaries.engine_start.right))) {
      setCurrentFlightSelected({...currentAircraftSelected, engine_start: dayjs(timeBoundaries.engine_start.right).subtract(1, 'minute').format(formatString)});
    }
  }

  const onSelectEngineStop = (d) => {
    // if d is out of left boundary, reset it to an valid time
    if (timeBoundaries.engine_stop.left && d.isBefore(dayjs(timeBoundaries.engine_stop.left))) {
      setCurrentFlightSelected({...currentAircraftSelected, engine_stop: dayjs(timeBoundaries.engine_stop.left).add(1, 'minute').format(formatString)});
    }
  }

  const onSelectTakeoff = (d) => {
    // if d is out of left boundary, reset it to an valid time
    if (timeBoundaries.takeoff.left && d.isBefore(dayjs(timeBoundaries.takeoff.left))) {
      setCurrentFlightSelected({...currentAircraftSelected, takeoff: dayjs(timeBoundaries.takeoff.left).add(1, 'minute').format(formatString)});
    // right boundary
    } else if (timeBoundaries.takeoff.right && d.isAfter(dayjs(timeBoundaries.takeoff.right))) {
      setCurrentFlightSelected({...currentAircraftSelected, takeoff: dayjs(timeBoundaries.takeoff.right).subtract(1, 'minute').format(formatString)});
    }
  };

  const onSelectLanding = (d) => {
    // if d is out of left boundary, reset it to an valid time
    if (timeBoundaries.landing.left && d.isBefore(dayjs(timeBoundaries.landing.left))) {
      setCurrentFlightSelected({...currentAircraftSelected, landing: dayjs(timeBoundaries.landing.left).add(1, 'minute').format(formatString)});
    // right boundary
    } else if (timeBoundaries.landing.right && d.isAfter(dayjs(timeBoundaries.landing.right))) {
      setCurrentFlightSelected({...currentAircraftSelected, landing: dayjs(timeBoundaries.landing.right).subtract(1, 'minute').format(formatString)});
    }
  }

  return (
    <>
      <Modal
        title="Edit Flight"
        open={editModalVisible}
        onCancel={handleClose}
        footer={[
          <Button onClick={handleClose}> Return </Button>,
          <Button onClick={handleCancel} danger>
            {" "}
            Delete{" "}
          </Button>,
          <Button onClick={form.submit} type="primary">
            {" "}
            Update{" "}
          </Button>,
        ]}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          onFinish={handleOk}
          onFinishFailed={onFinishFailed}
          onValuesChange={(values) => {
            onChange(values);
          }}
        >
          <Form.Item
            label="Registration"
            name="registration"
            rules={[
              {
                required: true,
                message: "Please input your Registration.",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Pilot"
            name="pilot_name"
            rules={[
              {
                required: true,
                message: "Please input your pilot name.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Engine Start"
            name="engine_start"
            rules={[
              {
                required: true,
                message: "Please input your engine start.",
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{
                format: "HH:mm",
                defaultValue: (timeBoundaries.engine_start.right ? dayjs(dayjs(timeBoundaries.engine_start.right).subtract(1, 'minute').format("HH:mm"), "HH:mm") : null),
              }}
              onChange={(value, dateString) => {
                onChangeDatePicker(value, timeTypes.TIME_TYPE_ENG_START);
              }}
              onOk={onDatePickerOk}
              onSelect={onSelectEngineStart}
              disabledDate={(d) => disabledEngineStartDate(d)}
              disabledTime={disabledEngineStartTime} // only when selected date is the edge date, we disable some time selections
            />
          </Form.Item>

          <Form.Item
            label="Engine Stop"
            name="engine_stop"
            rules={[
              {
                required: true,
                message: "Please input your engine stop.",
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{
                format: "HH:mm",
                defaultValue: (timeBoundaries.engine_stop.left ? dayjs(dayjs(timeBoundaries.engine_stop.left).add(1, 'minute').format("HH:mm"), "HH:mm") : null)
              }}
              onChange={(value, dateString) => {
                onChangeDatePicker(value, timeTypes.TIME_TYPE_ENG_STOP);
              }}
              onOk={onDatePickerOk}
              onSelect={onSelectEngineStop}
              disabledDate={(d) => disabledEngineStopDate(d)}
              disabledTime={disabledEngineStopTime}  // only when selected date is the edge date, we disable some time selections
            />
          </Form.Item>

          <Form.Item
            label="Takeoff Time"
            name="takeoff"
            rules={[
              {
                required: true,
                message: "Please input your takeoff time.",
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{
                format: "HH:mm",
              }}
              onChange={(value, dateString) => {
                onChangeDatePicker(value, timeTypes.TIME_TYPE_TAKEOFF);
              }}
              onOk={onDatePickerOk}
              onSelect={onSelectTakeoff}
              disabledDate={(d) => disabledTakeOffDate(d)}
              disabledTime={disabledTakeOffTime} // only when selected date is the edge date, we disable some time selections
            />
          </Form.Item>

          <Form.Item
            label="Landing Time"
            name="landing"
            rules={[
              {
                required: true,
                message: "Please input your landing time.",
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{
                format: "HH:mm",
              }}
              onChange={(value, dateString) => {
                onChangeDatePicker(value, timeTypes.TIME_TYPE_LANDING);
              }}
              onOk={onDatePickerOk}
              onSelect={onSelectLanding}
              disabledDate={(d) => disabledLandingDate(d)}
              disabledTime={disabledLandingTime} // only when selected date is the edge date, we disable some time selections
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

