import React, {useContext, createContext, useState} from "react";
import { HashRouter, BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import FirstLogin from "../views/firstLogin/FirstLogin";
import Login from "../views/login/Login";
import MainPage from "../views/mainPage/MainPage";
import FidsLayout from "../components/fids/FidsLayout";
import Map from "../components/fids/map/Map";
import AircraftSummary from "../components/fids/aircraftSummary/AircraftSummary";
import Flight from "../components/flight/Flight";
import Aircraft from "../components/aircraft/Aircraft";
import AccountLayout from "../components/accountManagment/AccountLayout";
import ManageAccounts from "../components/accountManagment/manageAccounts/ManageAccounts";
import CreateUser from "../components/accountManagment/createUser/CreateUser";
import Unsubscribe from "../components/accountManagment/unsubscribe/Unsubscribe";
import Registration from "../components/registration/Registration";
import PaymentPage from "../components/paymentPage/PaymentPage";
import ChangePassword from '../components/changePassword/ChangePassword';


export default function IndexRouter(props) {
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage/>}>
          <Route element={<FidsLayout/>}>
            <Route index element={<Map/>}/>
            <Route path="fids/map" element={<Map/>}/>
            <Route path="fids/aircraft-summary" element={<AircraftSummary/>}/>
          </Route>
          <Route path="flight" element={<Flight currentUserEmail={currentUserEmail}/>}/>
          <Route path="aircraft" element={<Aircraft/>}/>
         <Route  element={<AccountLayout/>}>
          
          <Route index element={<ManageAccounts/>}/>
          <Route path ="accountManagment/manageAccounts" element={<ManageAccounts/>}/>
          <Route path ="accountManagment/createUser" element={<CreateUser/>}/>
          <Route path="accountManagment/unsubscribe" element={<Unsubscribe/>}/>
         </Route>
          <Route path="*" element={<AccountLayout/>}/>
        </Route>
        <Route path="/login" element={<Login setCurrentUserEmail={setCurrentUserEmail}/>}/>
        <Route path="/registration" element={<Registration />} /> 
        <Route path="/paymentPage" element={<PaymentPage />} /> 
        <Route path="account-management/manage-accounts" element={<ManageAccounts/>}/>
        <Route path="changePassword" element={<ChangePassword />} />
      </Routes>
    </BrowserRouter>
  )
}
