import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';

const { Content } = Layout;

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%',
  minHeight: '250px',
  minWidth: '250px',
  lineHeight: '120px',
  color: '#fff',
};

//Top Navigation buttons
const topNavButtons = [
  {
    icon: UserOutlined,
    label: <Link to={"accountManagment/manageAccounts"}><Button block size={'middle'} >Manage Account</Button></Link>
  },
  {
    icon: VideoCameraOutlined,
    label: <Link to={"accountManagment/createUser"}><Button block size={'middle'} >Create User</Button></Link>
  },
  {
    icon: VideoCameraOutlined,
    label: <Link to={"accountManagment/unsubscribe"}><Button block size={'middle'} >Unsubscribe</Button></Link>
  }
];

const topNav = (
  <div
    style={{
      display: 'flex',
      width: "100%",
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
    >
        <Link style={{display:"flex", width: "100%", margin:"10px"}} to={"accountManagment/manageAccounts"}><Button block size={'middle'} style={{display: 'flex', justifyContent: 'center'}} >Manage Account</Button></Link>
        <Link style={{display:"flex", width: "100%", margin: "10px"}}  to={"accountManagment/createUser"}><Button block size={'middle'} style={{display: 'flex', justifyContent: 'center'}}>Create User</Button></Link>
        <Link style={{display:"flex", width: "100%", margin: "10px"}}  to={"accountManagment/unsubscribe"}><Button block size={'middle'} style={{display: 'flex', justifyContent: 'center'}}>Cancel Subscription</Button></Link>
    </div>
)
export default function AccountLayout() {
  return (
    <>
        <Layout>
            {topNav}
            <Content style={contentStyle}>
                <Outlet/>       
            </Content>
        </Layout>
    </>
  )
}