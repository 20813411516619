import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');


  const handleOk = () => {
    if (oldPassword && newPassword === confirmPassword && newPassword.length >= 8) {
      setLoading(true);

      const data = {
        email: email,
        old_password: oldPassword,
        new_password: newPassword,
      };

      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/change_pw', options)
        .then((response) => {
          setLoading(false);
          if (!response.ok) {
            throw new Error('Network response was not OK');
          }
          return response.text();
        })
        .then((data) => {
          console.log('Success: ', data);
          setIsSuccessModalVisible(true);
          closeModal();
        })
        .catch((error) => {
          console.log('Error: ', error);
          // Password change failed, you can show an error message or take any other action
        });
    } else {
      console.log('Password change failed');
      // Handle password mismatch or insufficient length error
      // Display error message or take appropriate action
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    // Implement any additional logic here if needed
  };

  const closeSuccessModal = () => {
    setIsSuccessModalVisible(false);
    navigate('/manageAccounts'); // Navigate back to "manageAccounts" page after closing the success message popup
    // Implement any additional logic here if needed
  };

  const navigate = useNavigate();

  const handleBack = () => {
    closeModal();
    navigate('/manageAccounts');
  };

  return (
    <>
      <Modal
        title="Change Password"
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleBack}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleBack}>
            Back
          </Button>,
          <Button key="change" type="primary" onClick={handleOk}>
            Change Password
          </Button>,
        ]}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        bodyStyle={{
          padding: '64px',
          width: '700px',
        }}
        maskClosable={false}
        closable={false}
      >
        <div>
          <p>Enter old password:</p>
          <Input.Password
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Old Password"
          />

          <p>Enter new password:</p>
          <Input.Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
          />

          <p style={{ marginTop: '10px' }}>Confirm new password:</p>
          <Input.Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm New Password"
          />
        </div>
      </Modal>

      {/* Success Message Popup */}
      <Modal
        title="Password Successfully Reset"
        centered
        visible={isSuccessModalVisible}
        onOk={closeSuccessModal}
        onCancel={closeSuccessModal}
        footer={[
          <Button key="ok" type="primary" onClick={closeSuccessModal}>
            OK
          </Button>,
        ]}
      >
        <p>Your password has been successfully reset!</p>
      </Modal>
    </>
  );
}
