import React, { useState } from 'react'
import { useNavigate, Navigate, useLocation, Outlet } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import SideBar from '../../components/sideBar/SideBar';
import ChangePassword from '../../components/changePassword/ChangePassword';

const { Header, Footer, Content } = Layout;

const layoutWrapperStyle = {
  width: '100%',
  height: '100%',
  display:'flex',

};

const headerStyle = {
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#fff',
  height: 70,
  lineHeight: '64px',
  fontSize: 45
};

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  // height: '500px', //this guy may be doing it
  // minHeight: '250px',
  // minWidth: '250px',
  lineHeight: '120px',
  color: '#fff',
  margin: '10px'
};

const footerStyle = {
  textAlign: 'center',
  color: '#fff'
};

export default function MainPage() {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("kmt_token")) || false); //if token exists, initialize state with it
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [token, setToken] =  useState(location.state ? location.state.token : false); //try getting access token from location state

  if(!token.access_token){
    return <Navigate to={"/login"}/>;
  }

  const logout = () => {
    localStorage.removeItem("kmt_token");
    setToken(false);
  };

  return (
    <>
      {/* {token.msg ? <ChangePassword /> : ""}{" "} */}
      {/* prompt to change pw if msg is not null */}
      <Layout style={layoutWrapperStyle}>
        <Header style={headerStyle}>
          Komet
          <Button
            type="primary"
            size="middle"
            icon={<LogoutOutlined />}
            onClick={logout}
          >
            Logout
          </Button>
        </Header>
        <Layout>
          <SideBar />
          <Content style={contentStyle}>
            <Outlet />
          </Content>
        </Layout>
        {/* <Footer style={footerStyle}>Footer</Footer> */}
      </Layout>
    </>
  );
}
