import React, { useState } from "react";
import { Modal, Form, Input, AutoComplete } from "antd";
import { AiOutlineWarning } from "react-icons/ai";

// Import style from Aircraft.css
import "./Aircraft.css";

export default function AddAircraftModal(props) {
  const {
    addModalVisible,
    setAddModalVisible,
    dataSource,
    setDataSource,
    token,
    setAlertMessage,
    setShowAlert,
    setAlertType,
    setLoading,
  } = props;

  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [newData, setNewData] = useState({
    key: "",
    registration: "",
    make: "",
    model: "",
    location: "",
    last_update: "",
  });

  const [form] = Form.useForm();

  const handleCancel = () => {
    setAddModalVisible(false);
  };

  const handleOk = (values) => {
    setWarningModalVisible(true);

    setNewData({
      key: `${values.registration}`,
      registration: `${values.registration}`,
      make: `${values.make}`,
      model: `${values.model}`,
      location: `${values.location}`,
      last_update: `${new Date().toJSON()}`,
    });
  };

  const handleWarningCancel = () => {
    setWarningModalVisible(false);
    setAddModalVisible(true);
  };

  const handleWarningOk = () => {
    const data = {
      registration: newData.registration,
      make: newData.make,
      model: newData.model,
      location: newData.location,
      last_update: newData.last_update,
    };

    setLoading(true);

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    fetch("https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/add", options)
      .then((response) => {
        if (!response.ok) {
            if (response.status === 400) {
              setAlertMessage("Aircraft already exists.");
              setShowAlert(true);
              setAlertType("warning");
            }
            setLoading(false);
            throw new Error("Network response was not OK");
          }
        return response.text();
      })
      .then((data) => {
        console.log("Success from Post: ", data);
        setAlertMessage("Aircraft added successfully.");
        setShowAlert(true);
        setAlertType("success");
        setLoading(false);
        setDataSource([...dataSource, newData]);
      })
      .catch((error) => {
        setAlertMessage("Error adding aircraft.");
        setShowAlert(true);
        setAlertType("error");
        setLoading(false);
        console.log("Error: ", error);
      });

    setAddModalVisible(false);
    setWarningModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    setAlertMessage("Error adding aircraft.");
    setShowAlert(true);
    setAlertType("error");
    console.log("Failed:", errorInfo);
  };

  const warningIconStyle = {
    color: "white",
    fontSize: "3.5em",
    marginLeft: "225px",
    marginBottom: "-20px",
  };

  const [searchOption, setSearchOption] = useState([]);

  const handleSearch = (value) => {
    console.log("Value: ", value.toUpperCase());

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(true);

    fetch(`https://komet-be-prod-1.azurewebsites.net/api/v1/airport/search/${value.toUpperCase()}`, options)
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        console.log("Response: ", response);
        return response.json();
      })
      .then((data) => {
        //if we're seeing login page, then token has not been set. Set token
        // console.log("Data: ", data.data);

        const searchResult = []
        data.data.forEach((item) => {
          searchResult.push(
            {
              value: `${item.iata_code}`,
              label: `${item.iata_code}`,
            },
          );
        });

        setSearchOption(searchResult);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  };
  
  return (
    <>
      <Modal
        centered
        forceRender
        title="Add Aircraft"
        open={addModalVisible && !warningModalVisible}
        onCancel={handleCancel}
        onOk={form.submit}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          onFinish={handleOk}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Registration"
            name="registration"
            rules={[
              {
                required: true,
                message: "Please input your Registration.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Make"
            name="make"
            rules={[
              {
                required: true,
                message: "Please input your Make.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Model"
            name="model"
            rules={[
              {
                required: true,
                message: "Please input your Model.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please input your Location.",
              },
            ]}
          >
            {/* <Input /> */}
            <AutoComplete
              style={{ borderRadius: 0, width: 230 }}
              options={searchOption}
              placeholder="Select AITA Code"
              filterOption={true}
              onSearch={(value) => handleSearch(value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      {warningModalVisible && (
        <Modal
          width={550}
          centered
          bodyStyle={{
            textAlign: "center",
            padding: "20px",
            marginBottom: "-20px",
          }}
          title={<AiOutlineWarning style={warningIconStyle} />}
          open={warningModalVisible}
          onCancel={handleWarningCancel}
          onOk={handleWarningOk}
          okText="Yes, Continue"
          cancelText="No, Cancel"
        >
          <h4>YOU ARE ABOUT TO MAKE CHANGES TO THE FLEET</h4>
          <p style={{ fontSize: "12px", marginTop: "10px" }}>
            This action will add this asset to your asset list.
          </p>
          <p
            style={{
              fontSize: "12px",
              marginBottom: "20px",
            }}
          >
            By adding a new aircraft your monthly subscription pricing will be
            affected.
          </p>
        </Modal>
      )}
    </>
  );
}
