import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';

const { Content } = Layout;



const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%',
  minHeight: '250px',
  minWidth: '250px',
  lineHeight: '120px',
  color: '#fff',
};

//Top Navigation buttons
const topNavButtons = [
    {
      icon: UserOutlined,
      label: <Link to={"fids/map"}><Button block size={'middle'} >Map</Button></Link>
    },
    {
      icon: VideoCameraOutlined,
      label: <Link to={"fids/aircraft-summary"}><Button block size={'middle'} >Aircraft Summary</Button></Link>
    },
  ]

const topNav = (
    // <Menu
    //     theme="dark"
    //     mode="horizontal"
        
    //     items={topNavButtons.map(
    //       (btn, index) => ({
    //         key: String(index + 1),
    //         label: btn.label,
    //       }),
    //     )}
    // />
    <div
    style={{
        display: 'flex',
        width: "100%",
        justifyContent: 'space-between',
        alignItems: 'center'
    }}
    >
        <Link style={{display:"flex", width: "100%", margin:"10px"}} to={"fids/map"}><Button block size={'middle'} style={{display: 'flex', borderRadius: 0, border: "1px solid black", justifyContent: 'center'}} >Map</Button></Link>
        <Link style={{display:"flex", width: "100%", margin: "10px"}}  to={"fids/aircraft-summary"}><Button block size={'middle'} style={{display: 'flex',  borderRadius: 0, border: "1px solid black", justifyContent: 'center'}}>Aircraft Summary</Button></Link>
    </div>
)
export default function FidsLayout() {
  return (
    <>
        <Layout>
            {topNav}
            <Content style={contentStyle}>
                <Outlet/>       
            </Content>
        </Layout>
    </>
    
  )
}