import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, AutoComplete } from "antd";

export default function EditAircraftModal(props) {
  const {
    editModalVisible,
    setEditModalVisible,
    currentAircraftSelected,
    handleUpdate,
    handleDelete,
    setAlertMessage,
    setShowAlert,
    setAlertType,
  } = props;

  const [form] = Form.useForm();
  const [newRowData, setNewRowData] = useState({
    key: currentAircraftSelected.key,
    registration: currentAircraftSelected.registration,
    make: currentAircraftSelected.make,
    model: currentAircraftSelected.model,
    location: currentAircraftSelected.location,
    last_update: currentAircraftSelected.last_update,
  });

  useEffect(() => {
    form.setFieldsValue({
      registration: currentAircraftSelected.registration,
      make: currentAircraftSelected.make,
      model: currentAircraftSelected.model,
      location: currentAircraftSelected.location,
    });
  }, [currentAircraftSelected]);

  const handleCancel = () => {
    handleDelete(newRowData);
    setEditModalVisible(false);
  };

  const handleOk = () => {
    handleUpdate(newRowData);
    setEditModalVisible(false);
  };

  const handleClose = () => {
    setEditModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setAlertMessage("Failed to update aircraft.");
    setShowAlert(true);
    setAlertType("error");
  };

  const onChange = (values) => {
    setNewRowData({
      ...newRowData,
      ...values,
    });
  };

  const [searchOption, setSearchOption] = useState([]);

  const handleSearch = (value) => {
    console.log("Value: ", value.toUpperCase());

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(
      `https://komet-be-prod-1.azurewebsites.net/api/v1/airport/search/${value.toUpperCase()}`,
      options
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not OK");
        }
        console.log("Response: ", response);
        return response.json();
      })
      .then((data) => {
        //if we're seeing login page, then token has not been set. Set token
        // console.log("Data: ", data.data);

        const searchResult = [];
        data.data.forEach((item) => {
          searchResult.push({
            value: `${item.iata_code}`,
            label: `${item.iata_code}`,
          });
        });

        setSearchOption(searchResult);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  return (
    <>
      <Modal
        title="Edit Aircraft"
        open={editModalVisible}
        onCancel={handleClose}
        footer={[
          <Button onClick={handleClose}> Return </Button>,
          <Button onClick={handleCancel} danger>
            {" "}
            Delete{" "}
          </Button>,
          <Button onClick={form.submit} type="primary">
            {" "}
            Update{" "}
          </Button>,
        ]}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          onFinish={handleOk}
          onFinishFailed={onFinishFailed}
          onValuesChange={(values) => {
            onChange(values);
          }}
        >
          <Form.Item
            label="Registration"
            name="registration"
            rules={[
              {
                required: true,
                message: "Please input your Registration.",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Make"
            name="make"
            rules={[
              {
                required: true,
                message: "Please input your Make.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Model"
            name="model"
            rules={[
              {
                required: true,
                message: "Please input your Model.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please input your Location.",
              },
            ]}
          >
            {/* <Input /> */}
            <AutoComplete
              style={{ width: 230 }}
              options={searchOption}
              placeholder="Select AITA Code"
              filterOption={true}
              onSearch={(value) => handleSearch(value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

