import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import {
  Button,
  Form,
  Input,
  Modal,
  Tabs,
  Layout,
  Space,
  Avatar,
  List,
  Table,
} from "antd";
import { EditFlightModal } from "./EditFlightModal";
import moment from "moment";
import "./Flight.css";

export default function Flight({ currentUserEmail }) {
  const companyName = localStorage.getItem("company_name");
  console.log("current user:", currentUserEmail);

  const [dataSource, setDataSource] = useState([{}]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(8);
  const [currentFlightSelected, setCurrentFlightSelected] = useState({
    key: "",
    registration: "",
    takeoff: "",
    landing: "",
    engine_start: "",
    engine_stop: "",
    pilot_name: "",
  });
  const [editModalVisible, setEditModalVisible] = useState(false);

  const moveInvalidDateToEnd = () => {
    const invalidVal = "Invalid Date";
    const invalidRows = dataSource.filter(item => (item.engine_stop === invalidVal || item.takeoff === invalidVal || item.landing === invalidVal));
    const validRows = dataSource.filter(item => !(item.engine_stop === invalidVal || item.takeoff === invalidVal || item.landing === invalidVal));
    setDataSource([...validRows, ...invalidRows]);
  };

  const handlePageSizeChange = (current, newSize) => {
    setPageSize(newSize);
  };

  const token = JSON.parse(localStorage.getItem("kmt_token")).access_token;

  const getData = () => {
    let options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(true);

    fetch(
      "https://komet-be-prod-1.azurewebsites.net/api/v1/flight/search/admin",
      options
    )
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success from Get: ", data);
        //if we're seeing login page, then token has not been set. Set token
        const newData = data.data
          .map((item, index) => {
            const pilot_name = item.first_name + " " + item.last_name;
            if (item.registration == "GEBB") {
              console.log(
                "start:",
                item.engine_start_time,
                "converted:",
                convertUTCToLocal(item.engine_start_time)
              );
            }
            const userIsAdmin = item.type === "admin" ? true : false;
            // Fallback for undefined values
            const key = item.last_update
              ? `${item.registration}-${item.last_update}`
              : `${item.registration}-${index}`;
            const registration = item.registration || "UnknownRegistration";
            const lastUpdate = item.last_update || `Fallback-${index}`;
            const flight_time =
              Number(item.flight_time) > 0 ? Number(item.flight_time) : 0;
            const air_time =
              Number(item.air_time) > 0 ? Number(item.air_time) : 0;
            return {
              key: `${registration}-${lastUpdate}`,
              registration: item.registration,
              takeoff: convertUTCToLocal(item.runway_takeoff_time),
              landing: convertUTCToLocal(item.runway_land_time),
              air_time: air_time,
              flight_time: flight_time,
              engine_start:
                item.engine_start_time !== null
                  ? convertUTCToLocal(item.engine_start_time)
                  : null,
              engine_stop: convertUTCToLocal(item.engine_stop_time),
              pilot_name: pilot_name,
              flight_id: item.flight_id,
            };
          })
          .sort((a, b) => {
            if (a.engine_start === b.engine_start) {
              return 0;
            }
            if (a.engine_start === null) {
              return 1;
            }
            if (b.engine_start === null) {
              return -1;
            }
            return a.engine_start < b.engine_start ? 1 : -1;
          });
        setLoading(false);
        setDataSource(newData);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  };

  // Get the data on first render
  useEffect(() => {
    getData();
  }, []);

  const formatDate = (value) => {
    let date = new Date(value);
    return date.toLocaleString();
  };

  const formatFlightTime = (flightTimeInSec) => {
    const hours = Math.floor(flightTimeInSec / 3600);
    const remainingSeconds = flightTimeInSec % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const totalHours = hours + roundToNearest(minutes);

    // Format the result into a string
    const formattedTime = `${totalHours}h`;

    return formattedTime;
  };

  const roundToNearest = (minutes) => {
    if (minutes <= 2) return 0.0;
    if (minutes <= 8) return 0.1;
    if (minutes <= 14) return 0.2;
    if (minutes <= 20) return 0.3;
    if (minutes <= 26) return 0.4;
    if (minutes <= 32) return 0.5;
    if (minutes <= 38) return 0.6;
    if (minutes <= 44) return 0.7;
    if (minutes <= 50) return 0.8;
    if (minutes <= 56) return 0.9;
    return 1.0;
  };

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);
      return d;
    });
  };
  const handleUpdate = (updatedRow) => {
    console.log("updating!", updatedRow);
    const newDataSource = [...dataSource];
    const time = `${new Date().toJSON()}`;
    const index = newDataSource.findIndex(
      (item) => updatedRow.key === item.key
    );
    const item = newDataSource[index];
    newDataSource.splice(index, 1, {
      ...item,
      ...updatedRow,
      last_update: time,
    });
    updatedRow.last_update = time;
    setLoading(true);

    let options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedRow),
    };

    fetch(
      "https://komet-be-prod-1.azurewebsites.net/api/v1/flight/update",
      options
    )
      .then((response) => {
        if (!response.ok) {
          setAlertMessage("Error updating aircraft.");
          setShowAlert(true);
          setAlertType("error");
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.text();
      })
      .then((data) => {
        setDataSource(newDataSource);
        console.log("Success from Update: ", data);
        setAlertMessage("Aircraft updated successfully.");
        setShowAlert(true);
        setAlertType("success");
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setAlertMessage("Error updating aircraft.");
        setShowAlert(true);
        setAlertType("error");
        setLoading(false);
      });
  };
  const handleDelete = (selectedRow) => {
    console.log("deleting!", selectedRow);

    setLoading(true);

    let options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      `https://komet-be-prod-1.azurewebsites.net/api/v1/flight/${selectedRow.flight_id}`,
      options
    )
      .then((response) => {
        if (!response.ok) {
          setAlertMessage("Cannot delete aircraft that has flights.");
          setShowAlert(true);
          setAlertType("warning");
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.text();
      })
      .then((data) => {
        console.log("Success from Delete: ", data);

        // Delete from the frontend if we successfully deleted from the backend
        const newDataSource = dataSource.filter(
          (item) => item.key !== selectedRow.key
        );
        setDataSource(newDataSource);
        setAlertMessage("Aircraft deleted successfully.");
        setShowAlert(true);
        setAlertType("success");
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setAlertMessage("Error deleting aircraft.");
        setShowAlert(true);
        setAlertType("error");
        setLoading(false);
      });
  };
  const handleRowClick = (record) => {
    setCurrentFlightSelected(record);
    setEditModalVisible(true);
  };
  
  /**
   * @param {*} sortBy "Date" or "Time"
   * @returns 
   */
  const handleDateTimeSort = (timeA, timeB, sortOrder, sortBy) => {
    // Check if either value is invalid, and sort accordingly
    let sortRes = 0;
    if (sortOrder === "ascend") {
      sortRes = 1;
    } else if (sortOrder === "descend") {
      sortRes = -1;
    }
    
    if (timeA === null || timeA === undefined || timeA === "Invalid Date") {
      return sortRes; // invalid values sorted to the end
    }
    if (timeB === null || timeB === undefined || timeB === "Invalid Date") {
      return sortRes; // invalid values sorted to the end
    }

    // If both values are valid, convert them to Date objects for comparison
    const d1 = new Date(timeA);
    const d2 = new Date(timeB);

    // only compare the Time part
    if (sortBy === "Time") {
      // Extract the time parts
      const timePartA = d1.getHours() * 3600 + d1.getMinutes() * 60 + d1.getSeconds();
      const timePartB = d2.getHours() * 3600 + d2.getMinutes() * 60 + d2.getSeconds();
      // return timePartA - timePartB;
      return timePartA < timePartB ? -1 : timePartA > timePartB ? 1 : 0;
    }

    // compare both the Date Time part
    return d1 < d2 ? -1 : d1 > d2 ? 1 : 0;
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "engine_start",
      align: "center", // Align the column header to center
      key: "date",
      sorter: (a, b, sortOrder) => handleDateTimeSort(a.engine_start, b.engine_start, sortOrder, "Date"),
      showSorterTooltip: false,
      render: (date, item) => {
        const formattedDateTime = moment(date).format("YYYY-MM-DD");
        return (
          <div onClick={() => handleRowClick(item)}>{formattedDateTime}</div>
        );
      },
    },
    {
      title: "Registration",
      dataIndex: "registration",
      align: "center", // Align the column header to center
      key: "registration",
      sorter: (a, b) => a.registration.localeCompare(b.registration),
      showSorterTooltip: false,
      onCell: (item) => {
        return {
          onClick: () => handleRowClick(item),
        };
      },
    },
    {
      title: "Pilot",
      dataIndex: "pilot_name",
      align: "center", // Align the column header to center
      key: "pilot",
      sorter: (a, b) => a.pilot_name.localeCompare(b.pilot_name),
      showSorterTooltip: false,
      onCell: (item) => {
        return {
          onClick: () => handleRowClick(item),
        };
      },
    },
    {
      title: "Engine Start",
      dataIndex: "engine_start",
      align: "center", // Align the column header to center
      key: "engine_start",
      sorter: (a, b, sortOrder) => handleDateTimeSort(a.engine_start, b.engine_start, sortOrder, "Time"),
      showSorterTooltip: false,
      render: (date, item) => {
        const formattedDateTime = moment(date).format("h:mm:ss A");
        return (
          <div onClick={() => handleRowClick(item)}>{formattedDateTime}</div>
        );
      },
    },
    {
      title: "Engine Stop",
      dataIndex: "engine_stop",
      align: "center", // Align the column header to center
      key: "engine_stop",
      sorter: (a, b) => {
        // Check if either value is null/undefined, and sort accordingly
        if (a.engine_stop === null || a.engine_stop === undefined) {
          return 1; // null/undefined values sorted to the end
        }
        if (b.engine_stop === null || b.engine_stop === undefined) {
          return 1; // null/undefined values sorted to the end
        }

        // If both values are valid, use localeCompare
        return a.engine_stop.localeCompare(b.engine_stop);
      },
      showSorterTooltip: false,
      render: (date, item) => {
        const formattedDateTime = moment(date).format("h:mm:ss A");
        return (
          <div onClick={() => handleRowClick(item)}>{formattedDateTime}</div>
        );
      },
    },
    {
      title: "Flight Time",
      dataIndex: "flight_time",
      align: "center", // Align the column header to center
      key: "flight_time",
      sorter: (a, b) => {
        // Check if either value is null/undefined, and sort accordingly
        if (a.flight_time === null || a.flight_time === undefined) {
          return 1; // null/undefined values sorted to the end
        }
        if (b.flight_time === null || b.flight_time === undefined) {
          return 1; // null/undefined values sorted to the end
        }

        // If both values are valid, use localeCompare
        return a.flight_time - b.flight_time;
      },
      showSorterTooltip: false,
      render: (time, item) => {
        const formattedTime = formatFlightTime(time);
        return <div onClick={() => handleRowClick(item)}>{formattedTime}</div>;
      },
    },
    {
      title: "Takeoff Time",
      dataIndex: "takeoff",
      align: "center", // Align the column header to center
      key: "takeoff",
      sorter: (a, b) => {
        // Check if either value is null/undefined, and sort accordingly
        if (a.takeoff === null || a.takeoff === undefined) {
          return 1; // null/undefined values sorted to the end
        }
        if (b.takeoff === null || b.takeoff === undefined) {
          return 1; // null/undefined values sorted to the end
        }
        // If both values are valid, use localeCompare
        return a.takeoff.localeCompare(b.takeoff);
      },
      showSorterTooltip: false,
      render: (date, item) => {
        const formattedDateTime = moment(date).format("h:mm:ss A");
        return (
          <div onClick={() => handleRowClick(item)}>{formattedDateTime}</div>
        );
      },
    },
    {
      title: "Landing Time",
      dataIndex: "landing",
      align: "center", // Align the column header to center
      key: "landing",
      sorter: (a, b) => {
        // Check if either value is null/undefined, and sort accordingly
        if (a.landing === null || b.landing === null) {
          return 1; // null/undefined values sorted to the end
        }

        // If both values are valid, use localeCompare
        return a.landing.localeCompare(b.landing);
      },
      showSorterTooltip: false,
      render: (date, item) => {
        const formattedDateTime = moment(date).format("h:mm:ss A");
        return (
          <div onClick={() => handleRowClick(item)}>{formattedDateTime}</div>
        );
      },
    },
    {
      title: "Air Time",
      dataIndex: "air_time",
      align: "center", // Align the column header to center
      key: "air_time",
      sorter: (a, b) => {
        // Check if either value is null/undefined, and sort accordingly
        if (a.air_time === null || b.air_time === null) {
          return 1; // null/undefined values sorted to the end
        }

        // If both values are valid, use localeCompare
        return a.air_time - b.air_time;
      },
      showSorterTooltip: false,
      render: (time, item) => {
        const formattedTime = formatFlightTime(time);
        return <div onClick={() => handleRowClick(item)}>{formattedTime}</div>;
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource}
        pagination={{
          pageSize,
          pageSizeOptions: ["8", "10", "20", "50", "100"],
          onShowSizeChange: handlePageSizeChange,
          showSizeChanger: true,
        }}
        columns={columns}
        className="data-table"
        size="middle"
        style={{
          width: "100%",
        }}
      ></Table>
      {editModalVisible && (
        <EditFlightModal
          editModalVisible={editModalVisible}
          setEditModalVisible={setEditModalVisible}
          currentAircraftSelected={currentFlightSelected}
          setCurrentFlightSelected={setCurrentFlightSelected}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          setAlertMessage={() => console.log("setAlert")}
          setShowAlert={() => console.log("setShowAlert")}
          setAlertType={() => console.log("setAlertType")}
          setLoading={() => console.log("setLoading")}
        />
      )}
    </div>
  );
  function convertUTCToLocal(utcString) {
    const date = new Date(utcString + "Z"); // Adding 'Z' ensures it's treated as UTC
    return date.toLocaleString(); // Converts to local time in a readable format
  }
  function convertLocalToUTC(localString) {
    const date = new Date(localString);
    const utcString = date.toISOString(); // Converts to UTC string format
    return utcString.slice(0, -1); // Remove the 'Z' character at the end
  }
}
