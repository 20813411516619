import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

const Unsubscribe = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);

    // Get the email from local storage
    const email = JSON.parse(localStorage.getItem("kmt_token"))?.email;

    // Check if the email is available before proceeding
    if (!email) {
      console.error("Email not available");
      setLoading(false);
      setIsModalVisible(false);
      return;
    }
    // Call the API endpoint for unsubscribing here
    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/cancel_subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }), // Include the email in the request body
    })
      .then(response => {
        setLoading(false);

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        console.log('Unsubscribe success');
      })
      .catch(error => {
        setLoading(false);
        console.error('Error:', error);
      });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  return (
    <Modal
      title="Unsubscribe Confirmation"
      visible={isModalVisible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={handleCancel}
      okText="Yes"
      cancelText="No"
    >
      <p>Are you sure you want to cancel your subscription?</p>
    </Modal>
  );
};

export default Unsubscribe;
