import React, { useRef, useEffect, useState, memo } from 'react';
import { Drawer } from 'antd';
import 'mapbox-gl/dist/mapbox-gl.css'; //https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/ also worth exploring: https://github.com/alex3165/react-mapbox-gl
import './Map.css'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoiY2pzaWdtb24iLCJhIjoiY2xqYnMwaXNuMXJ0NzNrbDRoN3h4bGUwaSJ9.497CujTdfrIBetkAhLFNBw';

// mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

// const map = new mapboxgl.Map({
//     container: 'map', // container ID
//     style: 'mapbox://styles/mapbox/streets-v12', // style URL
//     center: [-74.5, 40], // starting position [lng, lat]
//     zoom: 9, // starting zoom
// });mapbox://styles/mapbox/streets-v12

// retrieve map style
fetch('https://api.mapbox.com/styles/v1/mapbox/streets-v12?access_token=pk.eyJ1IjoiY2pzaWdtb24iLCJhIjoiY2xqYnMwaXNuMXJ0NzNrbDRoN3h4bGUwaSJ9.497CujTdfrIBetkAhLFNBw')
    .then((response)=>{
        if(!response.ok){
          console.log("failed connecting to mapbox")
          throw new Error('Network response was not OK');
        }
        return response.json();
    })
    .then((data)=>{
        console.log("Success with mapbox: ", data);
        //setCoordinates(data.data); //data property inside response obj
    })
    .catch((error)=>{
        console.log("Error: ", error);
    });

    const isValidNumber = (value) => typeof value === 'number' && !isNaN(value);

    const isValidLatitude = (lat) => isValidNumber(lat) && lat >= -90 && lat <= 90;

    const isValidLongitude = (lng) => isValidNumber(lng) && lng >= -180 && lng <= 180;

export default function Map() {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-114.375639);
  const [lat, setLat] = useState(51.104602);
  const [zoom, setZoom] = useState(5.6);
  const [coordinates, setCoordinates] = useState([]); 
  const [coordinates2, setCoordinates2] = useState([]); //may initialize to initial lng lat object
  const [drawerData, setDrawerData] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [count, setCount] = useState(50);
  const [responseHistory, setResponseHistory] = useState([]); 

  
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // const tempCoordinates = [
  //   {
  //   'coordinates': [-61.324462, -18.281518], //lng, lat
  //   'make': 'Boeing',
  //   'model': '747',
  //   'registration': '867B',
  //   'departure_airport': 'JFK',
  //   'arrival_airport': 'RDU'
  //   },
  //   {
  //   'coordinates': [-62.21582, -18.281518]
  //   },
  //   {
  //   'coordinates': [-63.292236, -19.281518]
  //   },
  //   {
  //   'coordinates': [-64.292236, -20.281518]
  //   },
  //   {
  //   'coordinates': [-65.292236, -21.281518]
  //   },
  //   {
  //   'coordinates': [-66.324462, -24.281518]
  //   }
  // ];


// this creates an array of object array, each array of objects contains a single flight's coordinates
// ordered most to least recent
 
  const transformedCoordinates = coordinates.map((flight) => {
    
    const { location_long, location_lat, make, model, registration, departure_airport, arrival_airport } = flight;  
    let lng, lat, lng2, lat2, lng3, lat3, prev_lng, prev_lat, mak, mod, reg, arri, dep;

    const defaultLngValue = count;  // Use your default values here
    const defaultLatValue = 45.7;

    if (Array.isArray(location_long) && location_long.length >= 3) {
        lng3 = isValidLongitude(location_long[2]) ? location_long[2] : defaultLngValue;
        lng2 = isValidLongitude(location_long[1]) ? location_long[1] : defaultLngValue;
        lng = isValidLongitude(location_long[0]) ? location_long[0] : defaultLngValue;
    } else {
        lng3 = lng2 = lng = defaultLngValue;
    }

    if (Array.isArray(location_lat) && location_lat.length >= 3) {
        lat3 = isValidLatitude(location_lat[2]) ? location_lat[2] : defaultLatValue;
        lat2 = isValidLatitude(location_lat[1]) ? location_lat[1] : defaultLatValue;
        lat = isValidLatitude(location_lat[0]) ? location_lat[0] : defaultLatValue;
    } else {
        lat3 = lat2 = lat = defaultLatValue;
    }
    // if (location_long !== null) {
    //   // Extract the floating-point coordinates from the strings
    //   const longMatch = location_long[0];
    //   const longMatch2 = location_long[1];
    //   const longMatch3 = location_long[2];
    //   // Convert the extracted substrings to floating-point numbers
    //   lng = longMatch;
    //   lng2 = longMatch2;
    //   lng3 = longMatch3;
    // } else {
    //   lng = count // this is an arbitrary value, should be removed once there are 
    //   // actual flight coords to pull from 
    // }
    // if (location_lat !== null) {
    //   // Extract the floating-point coordinates from the strings
    //   const latMatch = location_lat[0];
    //   const latMatch2 = location_lat[1];
    //   const latMatch3 = location_lat[2];
    //   // Convert the extracted substrings to floating-point numbers
    //   lat = latMatch;
    //   lat2 = latMatch2;
    //   lat3 = latMatch3;

    // } else {
    //   lat = 45.7 // another arbitrary value
    // }
    mak = make


    // this is where we need to add the cookie crumbs, right now they are the last 4 items in the array of object arrays and are arbitrary
    return [{ coordinates: [lng3, lat3], 'make': mak, 'model': model, registration: registration, departure_airport: departure_airport, arrival_airport: arrival_airport }, 
    {coordinates: [lng2, lat2], 'make': mak, 'model': model, registration: registration, departure_airport: departure_airport, arrival_airport: arrival_airport}, 
    {coordinates: [lng, lat], 'make': mak, 'model': model, registration: registration, departure_airport: departure_airport, arrival_airport: arrival_airport}];
  });


  //console.log(transformedCoordinates);
  

  var markersPopups = [];

//handle marker click
const handleMarkerClick = (pointInfo) => {
  // console.log(pointInfo.make + " " + pointInfo.model);
  setDrawerData(pointInfo);
  // console.log(pointInfo);
  setOpenDrawer(true);//toggle open drawer
}

const getBearing = (startLat, startLng, destLat, destLng) => {
  const startLatRad = (startLat * Math.PI) / 180;
  const startLngRad = (startLng * Math.PI) / 180;
  const destLatRad = (destLat * Math.PI) / 180;
  const destLngRad = (destLng * Math.PI) / 180;
  const dLng = destLngRad - startLngRad;
  const y = Math.sin(dLng) * Math.cos(destLatRad);
  const x = Math.cos(startLatRad) * Math.sin(destLatRad) - Math.sin(startLatRad) * Math.cos(destLatRad) * Math.cos(dLng);
  const bearing = Math.atan2(y, x);
  let bearingDegrees = (bearing * 180) / Math.PI; // Convert to degrees
  bearingDegrees = (bearingDegrees + 130) % 360; // Ensure the bearing is within the [0, 360) range
  return bearingDegrees;
};
//create marker icon svg
const createMarker = (type='tail', fill='#000000',stroke='black', width='27', height='30')=>{
  const markerSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  if(type == 'head'){
    const iconPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    markerSvg.setAttribute('fill', `${fill}`);
    markerSvg.setAttribute('viewBox', '0 0 50 50');
    // markerSvg.setAttribute('stroke', `${stroke}`);
    markerSvg.setAttribute('width', `${width}`);
    markerSvg.setAttribute('height', `${height}`);
  
    iconPath.setAttribute(
      'd',
      "m34.092.91821c1.2103 1.2454 1.2103 3.2507 0 4.4749l-7.9795 8.2111 4.3487 19.398-2.8923 2.9974-7.9589-15.683-8 8.2322.7384 5.2137-2.1949 2.2375-3.6102-6.7124-6.5436-3.7361 2.1744-2.2797 5.1282.781 7.9384-8.1689-15.241-8.2533 2.9128-2.9763 18.851 4.4749 7.9795-8.2111c1.1487-1.2243 3.2-1.2243 4.3487 0z"
    );
    // iconPath.setAttribute('stroke-linecap', 'round');
    // iconPath.setAttribute('stroke-linejoin', 'round');
    // iconPath.setAttribute('stroke-width', '1');
  
    markerSvg.appendChild(iconPath);
    return markerSvg;

  }
  else{

   const circles = document.createElementNS("http://www.w3.org/2000/svg", "circle");

    markerSvg.setAttribute('fill', `${fill}`);
    markerSvg.setAttribute('viewBox', '0 0 50 50');
    // markerSvg.setAttribute('stroke', `${stroke}`);
    markerSvg.setAttribute('width', `${width}`);
    markerSvg.setAttribute('height', `${height}`);
    
    circles.setAttribute("cx",20);
    circles.setAttribute("cy",20);
    circles.setAttribute("r",width/5);
  
    markerSvg.appendChild(circles);
    return markerSvg; 
  }

}
 
const getAircraftData = () => {
    const token = JSON.parse(localStorage.getItem("kmt_token")).access_token;
    let options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }
    fetch("https://komet-be-prod-1.azurewebsites.net/api/v1/flight/search/admin/active", options)
    .then((response)=>{
        if(!response.ok){
          console.log("COULD NOT FETCH AIRCRAFT DATA FOR MAP");
          throw new Error('Network response was not OK');
        }
        return response.json();
    })
    .then((data)=>{
        console.log("Success: HERE IS MY DATA", data.data);
        setCoordinates(data.data);
        setResponseHistory(prevHistory => { if (prevHistory.length === 3) { 
          // Remove the oldest response and add the new response 
          return [...prevHistory.slice(1), data.data]; } else { // Simply add the new response
             return [...prevHistory, data.data]; } });
              setCoordinates(data.data); setCoordinates2(coordinates);
               }) .catch((error) => { console.log("Error: ", error); }); 

  
}
//console.log("response", responseHistory[0])
//console.log("response1", responseHistory[1])
//console.log("response2", responseHistory[2])

//drawer component
const DrawerComponent = memo((props)=>{
  // console.log("drawer props");
  // console.log(props);
  return(
    <Drawer title="FLIGHT INFO" 
    placement="right" 
    onClose={()=>{setOpenDrawer(false)}} 
    open={openDrawer} 
    getContainer={false}
    rootStyle={{
      
    }}
    contentWrapperStyle={{
      right: 10,
      top: 50,
      height: 'fit-content',
      borderRadius: '15px',
    }}
    style={{
      borderRadius: '15px',
    }}
    >
        <p>{props.pointInfo.coordinates[0]+" "+props.pointInfo.coordinates[1]}</p>
        <p>{props.pointInfo.make + " " + props.pointInfo.model}</p>
    
        <p>{"reg: " +props.pointInfo.registration}</p>
        <p>{props.pointInfo.departure_airport  + " to " + props.pointInfo.arrival_airport}</p>
        {/* <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p> */}
    </Drawer>
  )
});
  
useEffect(()=>{
  getAircraftData();
  const interval = setInterval(getAircraftData, 15000); //call getAircraftData every two minutes // maybe see how to clear eventually to prevent leaking
  //console.log("HERE ARE MY FETCHED COORDS", coordinates);
},[]);

useEffect(() => {
  console.log("HERE ARE MY FETCHED COORDS", coordinates);
}, [coordinates]);



useEffect(() => {
    if (map.current) return; // runs every time, but if map is initialized already, do nothing
    //init map
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.addControl(new mapboxgl.FullscreenControl());

    //set map style properties on load
    map.current.on('load',()=>{//set theme colors
      map.current.setPaintProperty('land','background-color','#0091ff');
      map.current.setPaintProperty('landuse','fill-color','#1a90e3');
      map.current.setPaintProperty('landcover','fill-color','#025196');
      map.current.setPaintProperty('water','fill-color','#000000');
      map.current.setPaintProperty('water-depth','fill-color','#000000');
      map.current.setPaintProperty('waterway','fill-color','#99ddff');
      map.current.setPaintProperty('country-label','text-color','#ff0000');
      map.current.setPaintProperty('continent-label','text-color','#ff0000');
    })

    //handle map movement
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      
    });

});

useEffect(() => {
  if (!map.current) return; // wait for map to initialize
  map.current.on('move', () => {
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
  });
});


//generate markers and popups
useEffect(() => {
    if (!map.current) return; // runs everytime, but if map is not initialized, dont set marker
    // Create a default Marker and add it to the map.
    //will need to modify to match real dataSource
    transformedCoordinates.forEach((coordinateArr) => {
      coordinateArr.map((point, key)=>{
        const width = (coordinateArr.length - key) * (27 / coordinateArr.length)
        const height = (coordinateArr.length - key) * (30 / coordinateArr.length)
        
        let markerSvg = createMarker("tail","red", "red", 25, 25 ); //defaults to circle
        let popup = null

        let bearing = 0; // Default bearing
        console.log("points: ", point.coordinates[0], "points2: ", point.coordinates[1]);
        if (key + 1 < coordinateArr.length) {
          const nextPoint = coordinateArr[key + 1];
          bearing = getBearing(point.coordinates[1], point.coordinates[0], nextPoint.coordinates[1], nextPoint.coordinates[0]);
        }
    
        
        if(key == 0){
          markerSvg = createMarker("head","red", "red", width, height)
          if (Array.isArray(point.coordinates) && 
          point.coordinates.length === 2 && 
          typeof point.coordinates[0] === 'number' && 
          typeof point.coordinates[1] === 'number') {
    // Create the popup here
          popup = new mapboxgl.Popup({
          className: 'mapboxgl-popup',
          closeButton: false, 
          closeOnClick: false, 
          offset: 15,
          maxWidth: 'fit-content'
          })
        .setText(`${point.registration}`)
        .setLngLat(point.coordinates)
        .addTo(map.current); 
          } else {
            console.error("Invalid coordinates:", point.coordinates);
          }


        }
        
        const marker = new mapboxgl.Marker({
          element: markerSvg,
        })
        .setLngLat(point.coordinates)
        .setRotation(bearing)
        .addTo(map.current)
    
        marker.make = point.make; // Set the 'make' property to 'Boeing'
        marker.model = point.model;
        marker.departure_airport = point.departure_airport;
        marker.arrival_airport = point.arrival_airport;
        marker.registration = point.registration;

        const el = marker.getElement();
        // console.log(point);
        el.addEventListener("click",()=>{handleMarkerClick(point)});
  
        markersPopups.push({key, marker, popup});
      })
    });
    
    // tempCoordinates.map((point, key)=>{
    //   const width = (tempCoordinates.length - key) * (27 / tempCoordinates.length)
    //   const height = (tempCoordinates.length - key) * (30 / tempCoordinates.length)
      
    //   let markerSvg = createMarker("tail","red", "red", 25, 25 ); //defaults to circle
    //   let popup = null
    //   const bearing = getBearing(37.7749, -122.4194, 40.7128, -74.0060);

    //   if(key == 0){
    //     markerSvg = createMarker("head","red", "red", width, height)
    //     popup = new mapboxgl.Popup({
    //       className: 'mapboxgl-popup',
    //       closeButton: false, 
    //       closeOnClick: false, 
    //       offset: 15,
    //       maxWidth: 'fit-content'
    //    })
    //    .setText(`${point.coordinates[0]}, ${point.coordinates[1]}`)
    //    .setLngLat(point.coordinates)
    //    .addTo(map.current); 
    //   }
      
    //   const marker = new mapboxgl.Marker({
    //     element: markerSvg
    //   })
    //   .setLngLat(point.coordinates)
    //   .setRotation(bearing)
    //   .addTo(map.current)
  
    //   const el = marker.getElement();
    //   el.addEventListener("click",()=>{handleMarkerClick(point)});

    //   markersPopups.push({key, marker, popup});
    // })


    //prevent re-rendering of markers and popups everytime
    return () => {
      markersPopups.map((item, index)=>{
        item.marker.remove();
        if(item.popup){
          item.popup.remove();
        }
      })
      markersPopups = []
    }
});


return (
    <>
      <div className= "parent-container">
        <div ref={mapContainer} className="map-container" >
        {openDrawer && <DrawerComponent pointInfo={drawerData}/>}
        </div>
          <div className='sidebar'>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
          <div className='disclaimer'>
            Disclaimer: In case of disconnection, aircrafts may temporarily disappear
          </div>
      </div>
    </>
    
)
}