import React, {useState, useEffect} from 'react';
import { Table, Button } from 'antd';

const columns = [
  {
    align: 'center',
    title: 'Registration',
    dataIndex: 'registration',
    key: 'registration',
  },
  {
    align: 'center',
    title: 'Make',
    dataIndex: 'make',
    key: 'make',
  },
  {
    align: 'center',
    title: 'Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    align: 'center',
    title: 'Status',
    dataIndex: 'available',
    key: 'status',
    render: (status) => (
      status === true ? <Button type='primary' size='small' style={{background:"#7ED957", borderRadius: 50}}>available</Button>
      :<Button type='primary' size='small' style={{background:"#E38B06", borderRadius: 50}}>flying</Button>
    )
  }
]

export default function AircraftSummary() {
  const [data, setData] = useState([]);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  const getAircraftData = () => {
    
    const token = JSON.parse(localStorage.getItem("kmt_token")).access_token;

    let options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }
    fetch("https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/all", options)
    .then((response)=>{
        if(!response.ok){
          throw new Error('Network response was not OK');
        }
        return response.json();
    })
    .then((data)=>{
        console.log("Success: ", data.data);
        setData(data.data); //data property inside response obj
    })
    .catch((error)=>{
        console.log("Error: ", error);
    });
  }

  useEffect(getAircraftData,[]);

  return (
      <Table 
      bordered
      columns={columns} 
      dataSource={data} 
      pagination={{pageSize: 6,}}
      tableLayout={'auto'}
      style={{
        width:"100%",
        margin: "0 10px",
        textAlign: 'center'
      }}
      />      
  )
}