import React, { useState } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import login from './Login.module.css';
import video from '../../videos/login.mp4';
import { Button, Form, Input, Modal } from 'antd';
import ForgetPassword from '../../components/forgetPassword/ForgetPassword';

export default function Login({setCurrentUserEmail}) {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("kmt_token")) || false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const fieldStyle = {
    backgroundColor: 'pink!important',
    border: '1px solid rgb(60, 206, 255)',
    borderRadius: '0'
  };
  const pwFieldStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    border: '1px solid rgb(60, 206, 255)',
    borderRadius: '0'
  };

  const userLogin = (values) => {
    setLoading(true);

    const data = {
      email: values.email,
      password: values.password
    };

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch("https://komet-be-prod-1.azurewebsites.net/auth_api/login", options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((data) => {
        data.email = values.email;
        console.log("Success: ", data);

        setEmail(values.email);
        setCurrentUserEmail(values.email);

        localStorage.setItem("kmt_token", JSON.stringify(data));
        setToken(data);
        setLoading(false);

        navigate('/', { state: { token: data } });
      })
      .catch((error) => {
        console.log("Error: ", error);
        info("error", `${error}: Login Failed. Please try again`);
      });
  }

  const onFinish = (values) => {
    setLoading(true);
    console.log('Success:', values);
    userLogin(values);
  };
  

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const info = (status = "ok", msg) => {
    if (status === "ok") {
      console.log("message: ", msg);
      modal.success({
        title: 'Success',
        centered: true,
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Roger That',
        onOk() {},
      });
    } else {
      console.log("message: ", msg);
      modal.error({
        title: 'Error',
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Ok',
        onOk() {},
      });
    }
  };

  if (token.access_token) {
    return (<Navigate to={"/"} />);
  }

  return (
    <>
      <video autoPlay muted loop className={login.bgVideo}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          minWidth: "100%",
          minHeight: "100%",
          zIndex: "-10000000000",
          filter: "brightness(0.5)"
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className={login.login}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          color: "#ffffff",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <h1>KOMET</h1>
        <Form
          style={{
            minWidth: "500px",
            minHeight: "300px",
            padding: "20px"
          }}
          layout='vertical'
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            className='Label'
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email',
              },
            ]}
          >
            <Input className='Field' style={fieldStyle} />
          </Form.Item>

          <Form.Item
            className='Label'
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
          >
            <Input.Password className='Field' style={pwFieldStyle} />
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Form.Item
              name="forgetPassword"
            >
              <ForgetPassword />
            </Form.Item>

            <Form.Item
            >
              <Button className='Button' type="primary" htmlType="submit" loading={loading}>
                SIGN IN
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '-30px'
          }}
        >
          <Button
            type="link"
            onClick={() => {
              navigate('/registration');
            }}
            style={{
              color: '#ffffff',
              fontSize: '14px'
            }}
          >
            New User? Register Here
          </Button>
        </div>
        {contextHolder}
      </div>
    </>
  );
}
