import React, { useState } from 'react';
import './ForgetPassword.css';
import { Button, Form, Modal, Input, Typography } from 'antd';
const { Text } = Typography;

const ForgetPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    console.log('Success:', values);
    resetPw(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const resetPw = (values) => {
    const data = {
      target_email: values.email,
    };

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/reset_pw', options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.text();
      })
      .then((data) => {
        console.log('Success: ', data);
        setIsModalOpen(false); // Close pw modal
        setShowSuccessMessage(true); // Show the success message popup
      })
      .catch((error) => {
        console.log('Error: ', error);
        showErrorModal(`${error}: Password reset failed.`);
      });
  };

  const showErrorModal = (errorMsg) => {
    modal.error({
      title: 'Error',
      content: <p>{errorMsg}</p>,
      okText: 'Ok',
      onOk() {},
    });
  };

  return (
    <>
      <Text className='forgotpw' onClick={showModal}>
        Forgot Password?
      </Text>
      <Modal
        title='Forgot password?'
        centered
        destroyOnClose={true}
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        mousePosition={{
          x: 300,
          y: 300,
        }}
      >
        <Form
          preserve={false} // Don't preserve values after modal closes
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Email Address'
            name='email'
            rules={[{ required: true, type: 'email' }]}
          >
            <Input placeholder='Basic usage' />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        {contextHolder}
      </Modal>

      {/* Success Message Popup */}
      <Modal
        title='Success'
        visible={showSuccessMessage}
        centered
        onCancel={() => setShowSuccessMessage(false)}
        onOk={() => setShowSuccessMessage(false)}
      >
        <p>Please check your email for a temporary login.</p>
      </Modal>
    </>
  );
};

export default ForgetPassword;
