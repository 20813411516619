import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Tabs, Layout, Space, Avatar, List, Card, Table } from 'antd';

const { Column } = Table;

export default function ManageAccounts() {

  
  const [token, setToken] = useState(JSON.parse(localStorage.getItem('kmt_token')) || false);
  const [compName, setCompName] = useState(""); // New state variable to store the company name
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      name: "John Doe",
      email: "johndoe@example.com",
      status: "active"
    },
    {
      name: "Jane Smith",
      email: "janesmith@example.com",
      status: "inactive"
    },
    {
      name: "Alex Johnson",
      email: "alexjohnson@example.com",
      status: "active"
    }
  
  ]);

  useEffect(() => {
 
    fetchCompanyName();
  }, []);

  const fetchCompanyName = () => {
  
    const email = JSON.parse(localStorage.getItem("kmt_token"))?.email;

   
    if (!email) {
      console.error("Email not available");
      setLoading(false);
      return;
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // You might need to include the authorization token if required by your backend
        // 'Authorization': `Bearer ${token}`,
      },
    };

    fetch(`https://komet-be-prod-1.azurewebsites.net/auth_api/get_company_name?email=${email}`, options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((data) => {
        console.log("ManageAccounts: ", data);
        setCompName(data);
      })
      .catch((error) => {
        console.log("Error: ", error);
        info("error", `${error}: Failed to fetch company name.`);
      });
  };

  const info = (status = "ok", msg) => {
    if (status === "ok") {
      Modal.success({
        title: 'Success',
        centered: true,
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Roger That',
        onOk() {},
      });
    } else {
      Modal.error({
        title: 'Error',
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Ok',
        onOk() {},
      });
    }
  };

  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [filteredData, setFilteredData] = useState([]); // Add filteredData state
  const [loadings, setLoadings] = useState({
    search: false,
    dataFetch: true,
    reset: false,
    activate: false,
    deactivate: false
  });

  const navigate = useNavigate();
  const [modal] = Modal.useModal();

  const showConfirmationModal = useCallback((title, content, onOk) => {
    modal.confirm({
      title,
      icon: null,
      content,
      onOk,
      onCancel: () => {
        console.log('Confirm Cancel');
      }
    });
  }, [modal]);

  const resetPw = useCallback((email) => {
    const requestData = {
      target_email: email
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };

    setLoadings(prevLoadings => ({
      ...prevLoadings,
      reset: true
    }));

    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/reset_pw', options)
      .then(response => {
        setLoadings(prevLoadings => ({
          ...prevLoadings,
          reset: false
        }));

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }

        return response.text();
      })
      .then(data => {
        console.log('Success:', data);
        showConfirmationModal('Success', 'You have successfully reset your password');
      })
      .catch(error => {
        console.log('Error:', error);
        showConfirmationModal('Error', error);
      });
  }, [showConfirmationModal]);

  const activateAccount = useCallback((email) => {
    const token = JSON.parse(localStorage.getItem('kmt_token'));
    const requestData = {
      admin_email: token.email,
      target_email: email
    };

    const options = {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };

    setLoadings(prevLoadings => ({
      ...prevLoadings,
      activate: true
    }));

    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/account/activate', options)
      .then(response => {
        setLoadings(prevLoadings => ({
          ...prevLoadings,
          activate: false
        }));

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }

        return response.text();
      })
      .then(data => {
        console.log('Success:', data);
        showConfirmationModal('Success', 'You have successfully activated the account');
        setToggleRefresh(prevToggleRefresh => !prevToggleRefresh);
        info("ok", "Account Activated"); // Update this line to call info with status="ok"
      })
      .catch(error => {
        console.log('Error:', error);
        showConfirmationModal('Error', error);
      });
  }, [showConfirmationModal]);

  const deactivateAccount = useCallback((email) => {
    const token = JSON.parse(localStorage.getItem('kmt_token'));
    const requestData = {
      admin_email: token.email,
      target_email: email
    };

    const options = {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };

    setLoadings(prevLoadings => ({
      ...prevLoadings,
      deactivate: true
    }));

    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/account/deactivate', options)
      .then(response => {
        setLoadings(prevLoadings => ({
          ...prevLoadings,
          deactivate: false
        }));

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }

        return response.text();
      })
      .then(data => {
        console.log('Success:', data);
        showConfirmationModal('Success', 'You have successfully deactivated the account');
        setToggleRefresh(prevToggleRefresh => !prevToggleRefresh);
        info("ok", "Account Deactivated");
      })
      .catch(error => {
        console.log('Error:', error);
        showConfirmationModal('Error', error);
      });
  }, [showConfirmationModal]);

  const fetchData = useCallback(() => {
    const token = JSON.parse(localStorage.getItem('kmt_token'));
    if (!compName) {
      console.error('Company name not available');
      return;
    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token.access_token}`
      }
    };

    setLoadings(prevLoadings => ({
      ...prevLoadings,
      dataFetch: true
    }));

 
    fetch(`https://komet-be-prod-1.azurewebsites.net/auth_api/users_in_company/${compName}`, options)
      .then(response => {
        setLoadings(prevLoadings => ({
          ...prevLoadings,
          dataFetch: false
        }));

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }

        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        setData(data);
      })
      .catch(error => {
        console.log('Error:', error);
        showConfirmationModal('Error', error);
      });
  }, [compName, showConfirmationModal]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      fetchData();
    }
  }, [navigate, fetchData, token, compName]);

  const handleSearch = useCallback((value) => { 
     setLoadings(prevLoadings => ({
       ...prevLoadings,
       search: true
     }));

    const queryParams = new URLSearchParams({
      company_name: compName,
      search_string: value,
    });


    fetch(`https://komet-be-prod-1.azurewebsites.net/auth_api/search?${queryParams}`)
      .then(response => {
        setLoadings(prevLoadings => ({
          ...prevLoadings,
          search: false
        }));

        if (!response.ok) {
          throw new Error('Network response was not OK');
        }

        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        setFilteredData(data);
      })
      .catch(error => {
        console.log('Error:', error);
        showConfirmationModal('Error', error);
      });
  }, [compName]); ;

  return (
    <Layout>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Manage Accounts" key="1">
            <Form layout="inline" style={{ marginBottom: '16px' }}>
              <Form.Item>
                <Input.Search placeholder="Search by email" loading={loadings.search} onSearch={handleSearch} />
              </Form.Item>
            </Form>
            <Table
              dataSource={filteredData.length > 0 ? filteredData : data}
              loading={loadings.dataFetch}
              pagination={false}
            >
              <Column
                title="Name"
                key="name"
                render={(text, record) => `${record.first_name} ${record.last_name}`}
              />
              <Column title="Email" dataIndex="email" key="email" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space>
                    <Button
                      type="link"
                      onClick={() => {
                        navigate(`/changePassword?email=${record.email}`);
                      }}
                    >
                      Update Password
                    </Button>
                    
                    <Button
                      type="primary"
                      loading={loadings.activate}
                      onClick={() => activateAccount(record.email)}
                      disabled={record.status === "active"}
                    >
                      {record.status === "active" ? "Activated" : "Activate"}
                    </Button>
                    <Button
                      type="primary"
                      loading={loadings.deactivate}
                      onClick={() => deactivateAccount(record.email)}
                      disabled={record.status === "inactive"}
                    >
                      {record.status === "inactive" ? "Deactivated" : "Deactivate"}
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </Tabs.TabPane>
        </Tabs>
      </Space>
    </Layout>
  );
}
