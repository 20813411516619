import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import IndexRouter from './router/IndexRouter';
import { ConfigProvider } from 'antd';
//import AccountManagement from './components/accountManagment/AccountManagement'; 
//import ChangePassword from './components/changePassword/ChangePassword';

// https://ant.design/docs/react/customize-theme

const theme = {
  token:{
    colorPrimary:'#FFFFFF',
    controlHeight: 45,
    fontFamily: 'TTOctosquares-Regular',
    colorError: '#F10505',
    colorInfo: '#047DE6',
    colorSuccess: '#7ED957',
    colorWarning: '#E38B06',
    colorBorder: '#38B6FF',
    colorBorderSecondary: "#FFFFFF",
    colorPrimaryHover: '#38B6FF',
    colorSuccessText: '#7ED957',
    // colorTextHeading: '#FFFFFF',
    controlOutline: '#38B6FF',
    colorBgBase: '#051B2E',
    colorTextBase: '#FFFFFF'
  },
  components: {
    Button:{
      colorPrimary:'#051B2E',
      borderRadius: 4,
      fontFamily: 'TTOctosquares-Bold', //not reflecting 
      fontSize: 16,
      lineWidth: 0.5,
      controlOutline: 'none',
      
    },
    Menu:{
      colorPrimary:'#022C5A',
    },
    
  
  }
}
class App extends React.Component {
    render() {
      console.log("app started: ", process.env);
      return (
        <ConfigProvider
          theme={theme}
        >
          <div className="App">
              <IndexRouter></IndexRouter>
          </div>

        </ConfigProvider>
        
      );
    }
}

        
  
  // ========================================
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
  