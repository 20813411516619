import React, { useState, useEffect } from "react";
import { Table, Button, Alert, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import EditAircraftModal from "./EditAircraftModal";
import AddAircraftModal from "./AddAircraftModal";

export default function Aircraft() {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState({}); // e.g., { "aircraft1": false, "aircraft2": true }


  const [currentAircraftSelected, setCurrentAircraftSelected] = useState({
    registration: "",
    make: "",
    model: "",
    location: "",
    last_update: "",
  });
  const [dataSource, setDataSource] = useState([{}]);
  const token = JSON.parse(localStorage.getItem("kmt_token")).access_token;

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  //dummy data
  // const dummyData = [
  //   {
  //     key: "1",
  //     registration: "John Brown",
  //     make: 32,
  //     model: "New York No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "2",
  //     registration: "Jim Green",
  //     make: 42,
  //     model: "London No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "3",
  //     registration: "Joe Black",
  //     make: 32,
  //     model: "Sydney No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "4",
  //     registration: "John Snow",
  //     make: 32,
  //     model: "New York No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "5",
  //     registration: "Jim Brow",
  //     make: 42,
  //     model: "London No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "6",
  //     registration: "Joe Pesci",
  //     make: 32,
  //     model: "Sydney No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "7",
  //     registration: "John Gird",
  //     make: 32,
  //     model: "New York No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "8",
  //     registration: "Bo Green",
  //     make: 42,
  //     model: "London No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  //   {
  //     key: "9",
  //     registration: "Lop Jack",
  //     make: 32,
  //     model: "Sydney No. 1 Lake Park",
  //     location: [-61.21582, -15.971891],
  //     last_update: "20231402T12004MT",
  //   },
  // ];

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
    }, 2500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [showAlert]);

  const getData = () => {
    let options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(true);

    fetch("https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/all", options)
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success from Get: ", data.data);
        //if we're seeing login page, then token has not been set. Set token
        const newData = data.data.map((item) => {
          return {
            key: `${item.registration + item.last_update}`,
            registration: item.registration,
            make: item.make,
            model: item.model,
            location: item.location,
            last_update: item.last_update,
          };
        });
        setLoading(false);
        setDataSource(newData);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  };

  // Get the data on first render
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Registration",
      dataIndex: "registration",
      key: "registration",
      sorter: (a, b) => a.registration.localeCompare(b.registration),
      showSorterTooltip: false,
      render: (text, record) => (
        <div onClick={() => handleRowClick(record)}>
          {text}
        </div>)
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      sorter: (a, b) => a.make.localeCompare(b.make),
      showSorterTooltip: false,
      render: (text, record) => (
        <div onClick={() => handleRowClick(record)}>
          {text}
        </div>)
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      sorter: (a, b) => a.model.localeCompare(b.model),
      showSorterTooltip: false,
      render: (text, record) => (
        <div onClick={() => handleRowClick(record)}>
          {text}
        </div>)
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => a.location.localeCompare(b.location),
      showSorterTooltip: false,
      render: (text, record) => (
        <div onClick={() => handleRowClick(record)}>
          {text}
        </div>)
    },
    {
      title: "Reset Status",
      key: "resetStatus",
      render: (text, record) => (
        <Button 
        onClick={() => markAsAvailable(record)}
        disabled={disabledButtons[record.key]}
        >
          Mark as Available
        </Button>
      ),
    },
    {
      title: "Last Update",
      dataIndex: "last_update",
      key: "last_update",

      render: (text,record) => (
        <span style={{ color: "grey" }} onClick={() => handleRowClick(record)}>{`${formatDate(convertUTCToLocal(text))}`}</span>
      ),
    }
  ];

  const markAsAvailable = (record) => {
    // Disable the button for 5 seconds to prevent spamming
    setCurrentAircraftSelected(record);
    setDisabledButtons({...disabledButtons, [record.key]: true});
    setTimeout(() => {
      setDisabledButtons({...disabledButtons, [record.key]: false});
    }, 5000);
  };
  
  useEffect(() => {
    if (currentAircraftSelected.registration==="" || !disabledButtons[currentAircraftSelected.key]) return;
    let options = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ /* your request body */ }),
    };

    fetch(`https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/set_available/${currentAircraftSelected.registration}`, options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            return response.json();
        })
        .then(data => {
            console.log("Aircraft marked as available:", data);
            // Update your dataSource here if necessary
        })
        .catch(error => {
            console.error("Error marking aircraft as available:", error);
        });
}, [currentAircraftSelected,disabledButtons]);

// ... rest of the component


  // Change JSON date to string date/time format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString();

    return `${month}/${day}/${year} ${time}`;
  };

  const handleRowClick = (record) => {
    setCurrentAircraftSelected(record);
    setEditModalVisible(true);
  };

  // Handle Edit/Update for Each Row
  const handleUpdate = (updatedRow) => {
    const newDataSource = [...dataSource];
    const time = `${new Date().toJSON()}`;
    const index = newDataSource.findIndex(
      (item) => updatedRow.key === item.key
    );
    const item = newDataSource[index];
    newDataSource.splice(index, 1, {
      ...item,
      ...updatedRow,
      last_update: time,
    });

    const data = {
      registration: updatedRow.registration,
      make: updatedRow.make,
      model: updatedRow.model,
      location: updatedRow.location,
      last_update: time,
    };

    setLoading(true);

    let options = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch("https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/update", options)
      .then((response) => {
        if (!response.ok) {
          setAlertMessage("Error updating aircraft.");  
          setShowAlert(true);
          setAlertType("error");
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.text();
      })
      .then((data) => {
        console.log("Success from Update: ", data);
        setDataSource(newDataSource);
        setAlertMessage("Aircraft updated successfully.");
        setShowAlert(true);
        setAlertType("success");
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setAlertMessage("Error updating aircraft.");
        setShowAlert(true);
        setAlertType("error");
        setLoading(false);
      });
  };

  // Handle Delete for Each Row
  const handleDelete = (currentRow) => {
    // Delete from the backend
    const data = {
      target_registration: currentRow.registration,
    };

    setLoading(true);

    let options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      `https://komet-be-prod-1.azurewebsites.net/api/v1/aircraft/remove?target_registration=${data.target_registration}`,
      options
    )
      .then((response) => {
        if (!response.ok) {
          setAlertMessage("Cannot delete aircraft that has flights.");
          setShowAlert(true);
          setAlertType("warning");
          setLoading(false);
          throw new Error("Network response was not OK");
        }
        return response.text();
      })
      .then((data) => {
        console.log("Success from Delete: ", data);

        // // Delete from the frontend if we successfully deleted from the backend
        const newDataSource = dataSource.filter(
          (item) => item.key !== currentRow.key
        );
        setDataSource(newDataSource);
        setAlertMessage("Aircraft deleted successfully.");
        setShowAlert(true);
        setAlertType("success");
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setAlertMessage("Error deleting aircraft.");
        setShowAlert(true);
        setAlertType("error");
        setLoading(false);
      });
  };

  const mergedColumns = columns.map((col) => {
    return col;
  });

  const antIcon = (
    <LoadingOutlined
      style={{
        position: "absolute",
        top: "40%",
        right: "45%",
        fontSize: 48,
        zIndex: 1000,
      }}
      spin
    />
  );

      function convertUTCToLocal(utcString) {
      const date = new Date(utcString + 'Z'); // Adding 'Z' ensures it's treated as UTC
      return date.toLocaleString(); // Converts to local time in a readable format
    }

  return (
    <>
      {loading ? <Spin indicator={antIcon} /> : null}
      <div
        style={{
          position: "absolute",
          right: "20vh",
          minWidth: "250px",
        }}
      >
        {showAlert && alertType === "success" && (
          <Alert
            message={alertMessage}
            type="success"
            banner={true}
            style={{ marginTop: "15px", color: "black" }}
          />
        )}
        {showAlert && alertType === "warning" && (
          <Alert
            message={alertMessage}
            type="warning"
            banner={true}
            style={{ marginTop: "15px", color: "black" }}
          />
        )}
        {showAlert && alertType === "error" && (
          <Alert
            message={alertMessage}
            type="error"
            banner={true}
            style={{ marginTop: "15px", color: "black" }}
          />
        )}
      </div>

      {addModalVisible && (
        <AddAircraftModal
          addModalVisible={addModalVisible}
          setAddModalVisible={setAddModalVisible}
          dataSource={dataSource}
          setDataSource={setDataSource}
          token={token}
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
          setAlertType={setAlertType}
          setLoading={setLoading}
        />
      )}

      {editModalVisible && (
        <EditAircraftModal
          editModalVisible={editModalVisible}
          setEditModalVisible={setEditModalVisible}
          currentAircraftSelected={currentAircraftSelected}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          setAlertMessage={setAlertMessage}
          setShowAlert={setShowAlert}
          setAlertType={setAlertType}
          setLoading={setLoading}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setAddModalVisible(true);
            }}
            type="primary"
            size={"middle"}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            Add New Aircraft
          </Button>
        </div>

        <Table
          dataSource={dataSource} //change later
          columns={mergedColumns}
          pagination={{ pageSize: 10 }}
        //   onRow={(record, rowIndex) => {
        //     return {
        //       onClick: (e) => {
        //         handleRowClick(record);
        //       }, // click row
        //     };
        //   }
        // }
        />
      </div>
    </>
  );
}
