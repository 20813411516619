import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Layout, Tabs } from 'antd';

export default function CreateUser() {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("kmt_token")) || false);
  const { Content } = Layout;
  const [loading, setLoading] = useState(false);
  const [compName, setCompName] = useState(""); // New state variable to store the company name
  useEffect(() => {
    // Fetch the company name when the component mounts
    fetchCompanyName();
  }, []);

  const fetchCompanyName = () => {
    // Get the email from local storage
    const email = JSON.parse(localStorage.getItem("kmt_token"))?.email;

    // Check if the email is available before proceeding
    if (!email) {
      console.error("Email not available");
      setLoading(false);
      return;
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // You might need to include the authorization token if required by your backend
        // 'Authorization': `Bearer ${token}`,
      },
    };

    fetch(`https://komet-be-prod-1.azurewebsites.net/auth_api/get_company_name?email=${email}`, options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((data) => {
       console.log("Success: ", data);
       setCompName(data);            
      })
      .catch((error) => {
        console.log("Error: ", error);
        info("error", `${error}: Failed to fetch company name.`);
      });
  };

  const handleGeneratePassword = (formInst) => {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 10;
    let password = "";

    const array = new Uint32Array(chars.length);
    crypto.getRandomValues(array);

    for (let i = 0; i < passwordLength; i++) {
      password += chars[array[i] % chars.length];
    }

    formInst.setFieldsValue({ password });
  };

  const createUser = (values) => {
    const data = {
      email: values.email,
      password: values.password,
      last_name: values.lastname,
      first_name: values.firstname,
      company_name: compName, 
      need_update_pw: false,
      type: values.type,
      last_update: new Date().toISOString(),
    };
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch("https://komet-be-prod-1.azurewebsites.net/auth_api/create_user_within_account", options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        response.json();
      })
      .then((data) => {
        console.log("Success: ", data);
        info("ok", "Account Created."); //use tiny alert box instead
      })
      .catch((error) => {
        console.log("Error: ", error);
        info("error", `${error}: Account creation failed.`);
      });
  };

  const onFinish = (values, type) => {
    setLoading(true);
    values.type = type;
    console.log("Success: ", values);
    createUser(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const info = (status = "ok", msg) => {
    if (status === "ok") {
      Modal.success({
        title: 'Success',
        centered: true,
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Roger That',
        onOk() {},
      });
    } else {
      Modal.error({
        title: 'Error',
        content: (
          <div>
            <p>{msg}</p>
          </div>
        ),
        okText: 'Ok',
        onOk() {},
      });
    }
  };

  const PilotForm = () => {
    const [form] = Form.useForm();

    return (
      <Form
        form={form}
        name="pilotform"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={(values) => onFinish(values, "pilot")}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: 'First Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: 'Last Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Password">
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Create Password' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button type='primary' block onClick={() => handleGeneratePassword(form)}>Generate a Temp. Password</Button>
        </Form.Item>

        <Form.Item>
          <Button type='primary' block htmlType='submit' loading={loading}>Create Account</Button>
        </Form.Item>
      </Form>
    );
  };

  const AdminForm = () => {
    const [form] = Form.useForm();

    return (
      <Form
        form={form}
        name="adminform"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={(values) => onFinish(values, "admin")}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: 'First Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: 'Last Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Password">
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Create password' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button type='primary' block onClick={() => handleGeneratePassword(form)}>Generate a Temp. Password</Button>
        </Form.Item>

        <Form.Item>
          <Button type='primary' block htmlType='submit' loading={loading}>Create Account</Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Layout>
      <Content>
        <Tabs defaultActiveKey="pilot" centered>
          <Tabs.TabPane tab="Pilot" key="pilot">
            <PilotForm />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Admin" key="admin">
            <AdminForm />
          </Tabs.TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
}
