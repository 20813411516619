import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import video from '../../videos/login.mp4';

export default function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [stripe, setStripe] = useState(null);

  const fieldStyle = {
    backgroundColor: 'pink!important',
    border: '1px solid rgb(60, 206, 255)',
    borderRadius: '0'
  };
  const pwFieldStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    border: '1px solid rgb(60, 206, 255)',
    borderRadius: '0'
  };

  // Initialize Stripe with your publishable key
  const initializeStripe = async () => {
    const stripe = await loadStripe('pk_test_51Lq2WIEZNmjdxMVJxfpgK1aV2ScJERXuoIqumJQptwA6bvoS51q4wmhtV1OB3sdfZ2PY3rIZIEBGZGew636SJb9c002BR8T4Tb');
    setStripe(stripe);
  };

  // Call the initializeStripe function when the component mounts
  useEffect(() => {
    initializeStripe();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      email: values.email,
      last_name: values.last_name,
      first_name: values.first_name,
      password: values.password,
      disabled: false,
      need_update_pw: false,
      type: 'admin',
      last_update: new Date().toISOString(),
      company_name: values.company_name,
    };
    const options = {
      method: 'POST',
      headers: {
        'Authorization': 'pk_test_51Lq2WIEZNmjdxMVJxfpgK1aV2ScJERXuoIqumJQptwA6bvoS51q4wmhtV1OB3sdfZ2PY3rIZIEBGZGew636SJb9c002BR8T4Tb',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(data),
    };
    fetch('https://komet-be-prod-1.azurewebsites.net/auth_api/create_user', options)
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((responseData) => {
        console.log('Success:', responseData);
        // Check if the response contains the payment intent client secret
        if (responseData.checkout_session_id) {
          // Use the client secret to redirect to the Stripe payment landing page
          redirectToPaymentPage(responseData.checkout_session_id);
        } else {
          // Handle successful registration without redirecting to payment
          info('success', 'Registration successful!');
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        // Handle registration error
        info('error', 'Registration Failed. Please try again.');
      });
  };

  // Function to redirect to the Stripe payment page
  const redirectToPaymentPage = async (sessionId) => {
    try {
      const stripe = await loadStripe('pk_test_51Lq2WIEZNmjdxMVJxfpgK1aV2ScJERXuoIqumJQptwA6bvoS51q4wmhtV1OB3sdfZ2PY3rIZIEBGZGew636SJb9c002BR8T4Tb');
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe Checkout Error:', error);
      } 
    } catch (error) {
      console.error('Stripe Checkout Error:', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const info = (status = 'ok', msg) => {
    if (status === 'ok') {
      console.log('message:', msg);
      modal.success({
        title: 'Success',
        centered: true,
        content: <p>{msg}</p>,
        okText: 'OK',
        onOk() {},
      });
    } else {
      console.log('message:', msg);
      modal.error({
        title: 'Error',
        content: <p>{msg}</p>,
        okText: 'OK',
        onOk() {},
      });
    }
  };

  return (
    <>
      <video autoPlay muted loop style={{ position: 'fixed', top: '0', left: '0', minWidth: '100%', minHeight: '100%', zIndex: '-10000000000' }}>
        <source src={video} type="video/mp4" />
      </video>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
        <h1>Registration</h1>
        <Form
          style={{ minWidth: '500px', padding: '20px' }}
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Company Name" // New field label
            name="company_name" // New field name
            rules={[{ required: true, message: 'Please input your company name!' }]} // New field validation rules
          >
            <Input className='Field' style={fieldStyle}/>
          </Form.Item>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input className='Field' style={fieldStyle}/>
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input className='Field' style={fieldStyle}/>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, type: 'email', message: 'Please input a valid email!' },
            ]}
          >
            <Input className='Field' style={fieldStyle}/>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input a password!' }]}
          >
            <Input className='Field' style={fieldStyle}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft: '375px' }}> 
              Register
            </Button>
          </Form.Item>
        </Form>
        {contextHolder}
      </div>
    </>
  );
}